<template>
  <form
    id="applicationSubmission"
    @submit.prevent="handleSubmit"
  >
    <div class="grid-container">
      <div class="grid-y grid-margin-x">
        <h2><b>{{ $t("message.resultsTitle") }}</b></h2>
        <!-- <h3>{{ $t("message.resultsResultsLabel") }}</h3> -->
        <div>{{ $t("message.secondResultsDescription") }}</div>
        <br>

        <div
          v-for="(program, idx) in programs"
          :key="idx"
        >
          <div
            v-if="program.isVisible"
          >
            <!-- Bullet Point Programs -->
            <template v-if="['Housing Counseling', 'Tangled Title', 'Neighborhood Energy Centers', 'Longtime Owner Occupants Program', 'Homestead Exemption Application'].includes(program.name)">
              <!--{{program.name}}-->
              <label style="color: #0f4d90;">{{ program.name }}:</label>
              <ul>
                <li>
                  <span>{{ $t(program.description) }} &#8226;</span>
                  <span>{{ program.phoneNumber }} &#8226;</span>
                  <component
                    :is="program.website ? 'a' : 'span'"
                    :href="program.website || ''"
                    target="_blank"
                  >
                    {{ $t("message.websiteLabel") }}
                  </component>
                </li>
                <li v-if="program.links">
                  <component
                    :is="program.links ? 'a' : 'span' "
                    :href="program.links || '' "
                    target="_blank"
                  >
                    {{ $t("message.secondWebsiteLabel") }}
                  </component>
                </li>
              </ul>
            </template>
            <!-- Display other programs as checkboxes -->
            <input
              v-else
              :id="program.name"
              v-model="program.applicantInterested"
              type="checkbox"
              role="checkbox"
              :aria-checked="program.applicantInterested"
              class="checkbox-input"
              @keydown="handleKeydown"
            >
            <label 
              v-if="!['Housing Counseling', 'Tangled Title', 'Neighborhood Energy Centers', 'Longtime Owner Occupants Program', 'Homestead Exemption Application'].includes(program.name)"
              :for="program.name" 
              :tabindex="0"
            > 
              {{ program.name }}:
            </label>
            <br>
            <!-- Remove extra description, phone number website and Link -->
            <template v-if="!['Housing Counseling', 'Tangled Title', 'Neighborhood Energy Centers', 'Longtime Owner Occupants Program', 'Homestead Exemption Application'].includes(program.name)">
              <p style="margin-left: 0.8%">
                {{ $t(program.description) }} &#8226;
                {{ program.phoneNumber }} &#8226;
                <component
                  :is="program.website ? 'a' : 'span'"
                  :href="program.website || ''"
                  target="_blank"
                >
                  {{ $t("message.websiteLabel") }}
                </component>
                <br>
              </p>
              <p
                v-if="program.links"
                style="margin-left: 0.8%"
              >
                <component
                  :is="program.links ? 'a' : 'span' "
                  :href="program.links || '' "
                  target="_blank"
                >
                  {{ $t("message.secondWebsiteLabel") }}
                </component>
                <br>
              </p>
              <br>
            </template>
          </div>
        </div>
        <div
          v-if="resultsValid == true && $v.applicantInterestedList.$invalid"
          class="love-park-red"
        >
          {{ $t("message.resultsSelectProgram") }}
        </div>
        <div v-if="resultsValid == false">
          <b>{{ $t("message.resultsNoQualifications")
          }} <a href="./">{{ $t("message.resultsReturnToHomepage") }}</a></b>
        </div>
        <br>
                
        <div class="cell">
          <h4
            v-if="submitStatus === 'OK'"
            class="typo__p"
          >
            {{ $t("message.resultsSubmissionThanks") }}
          </h4>
          <h4
            v-if="submitStatus === 'PENDING'"
            class="loader"
          >
            {{ $t("message.resultsSubmitting") }}
            <span class="loader__dot">.</span>
            <span class="loader__dot">.</span>
            <span class="loader__dot">.</span>
          </h4>
          <h4 v-if="submitStatus === 'POST_ERROR'">
            {{ $t("message.resultsSubmissionError") }}
          </h4>
        </div>
        <div class="grid-x grid-padding-x align-right">
          <div class="cell medium-4">
            <button
              :disabled="submitStatus === 'PENDING' || submitStatus === 'OK'"
              class="button"
            >
              {{ $t("message.resultsContactButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// import {
//   buildApplicationDataObject,
//   dataObjectToXml,
// } from "../utilities/functions";
// import axios from "axios";
import { required } from 'vuelidate/lib/validators';
import { map } from 'lodash';
export default {
  name: "Third",
  props: {
    adultsPath: { type: String, default: null },
    childPath: { type: String, default: null },
    incomePath: { type: String, default: null },
    yearsPath: { type: String, default: null },
    disabilityPath: { type: String, default: null },
    primaryResidencePath: { type: String, default: null },
    homeownerPath: { type: String, default: null },
    spropertyPath: { type: String, default: null },
    deedPath: { type: String, default: null },
    referralPath: { type: String, default: null },
    employeePath: { type: String, default: null },
    roofLeakPath: { type: String, default: null },
    electricProblemPath: { type: String, default: null },
    plumbingProblemPath: { type: String, default: null },
    structuralProblemPath: { type: String, default: null },
    floorStairsPath: { type: String, default: null },
    heaterOrHotWaterPath: { type: String, default: null },
    helpInHomePath: { type: String, default: null },
    tangleTitlePath: { type: String, default: null },
    solarPowerPath: { type: String, default: null },
    billProblemPath: { type: String, default: null },
    shiaEmailPath: { type: String, default: null },
    encryptedLinkPath: { type: String, default: null },
  },
  data() {
    return {
      submitStatus: null,
      Name: null,
      EmailAddress: null,
      Address: {
        Street1: null,
        Street2: null,
        City: null,
        State: null,
        Zip: null,
      },
      Phone: null,
      resultsValid: false,
      // TripleRSelection: null,
      programs: [
        {
          // If you comment out website, the website will show undefined through the email link.
          id: 1,
          name: "Adaptive Modification Program", //complete
          applicantInterested: false,
          phoneNumber: "215-448-2160",
          description: "message.amodDescription",
          website: "https://phdcphila.org/residents/home-repair/adaptive-modifications-program/",
          //this is where the new SHIA website will be pasted
          //links: this.encryptedLinkPath,
          isVisible: false,
        },
        {
          id: 2,
          name: "Basic Systems Repair Program", //complete
          applicantInterested: false,
          phoneNumber: "215-448-2160",
          description: "message.bsrpDescription",
          website: "https://phdcphila.org/residents/home-repair/basic-systems-repair-program/",
          //this is where the new SHIA website will be pasted
          // links: this.encryptedLinkPath,
          isVisible: false,
        },
        {
          id: 3,
          name: "Built to Last",
          applicantInterested: false,
          phoneNumber: "215-686-4483",
          description: "message.builtToLastDescription",
          website: "https://philaenergy.org/programs-initiatives/built-to-last/",
          //this is where the new SHIA website will be pasted
          // links: this.encryptedLinkPath,
          isVisible: false,
    
        },
        {
          id: 4,
          name: "Heater Hotline", //complete
          applicantInterested: false,
          phoneNumber: "215-568-7190",
          description: "message.heaterDescription",
          website: "https://www.ecasavesenergy.org/heater-hotline",
          //this is where the new SHIA website will be pasted
          // links: this.encryptedLinkPath,
          isVisible: false,
        },
        {
          id: 5, //complete
          name: "Housing Counseling",
          applicantInterested: false,
          phoneNumber: "215-686-9749",
          description: "message.housingCounselingDescription",
          website: "https://www.phila.gov/departments/division-of-housing-and-community-development/neighborhood-resources/housing-counseling/",
          isVisible: false,
                
        },
        {
          id: 6,
          name: "Neighborhood Energy Centers",
          applicantInterested: false,
          description: "message.necDescription",
          website: "https://www.phila.gov/departments/division-of-housing-and-community-development/neighborhood-resources/neighborhood-energy-centers-necs/",
          isVisible: false,
        },
        {
          id: 7,
          name: "PEA-Solarize Philly Program",
          applicantInterested: false,
          phoneNumber: "215-686-4483",
          description: "message.solarPowerDescription",
          website: "https://solarizephilly.org/",
          isVisible: false,
        },
        {
          id: 8,
          name: "Philly First Home", //complete
          applicantInterested: false,
          phoneNumber: "215-686-9749",
          description: "message.pfhDescription",
          website: "https://phdcphila.org/residents/homebuyers-and-renters/philly-first-home/",
          isVisible: false,
        },
        {
          id: 9,
          name: "Restore Repair Renew Loan Program", //complete
          applicantInterested: false,
          phoneNumber: "Call Clarifi (215-866-5200) to begin the intake process. ", //awilliams@philapcca.org, rrr@clarifi.org
          description: "message.tripleRDescription",
          website: "https://phdcphila.org/residents/home-repair/restore-repair-renew",
          isVisible: false,
        },
        {
          id: 10,
          name: "Tangled Title", //complete
          applicantInterested: false,
          phoneNumber: "215-523-9564", //reverify phone
          description: "message.tangledTitleDescription", //old description: Grants to assist low-income residents gain clear legal title to their home.
          website: "https://www.phillyvip.org/tangled-title-fund/", 
          isVisible: false,
        },
        {
          id: 11,
          name: "Turn the Key", //complete
          applicantInterested: false,
          phoneNumber: "215-448-3000",
          description: "message.turnTheKeyDescription",
          website: "https://phdcphila.org/residents-and-landlords/homebuyers-and-renters/turn-the-key/",
          isVisible: false,
        },
        {
          id: 12,
          name: "Utility Grant Program", //complete
          applicantInterested: false,
          phoneNumber: "215-972-5170",
          description: "message.utilityGrantDescription",
          website: "https://dced.pa.gov/programs/",
          isVisible: false,
        },
        {
          id: 13,
          name: "Weatherization Assistance Program",
          applicantInterested: false,
          phoneNumber: "1-866-466-3972",
          description: "message.wapDescription",
          website: "https://dced.pa.gov/programs/weatherization-assistance-program-wap/",
          //this is where the new SHIA website will be pasted/ waiting on WAP to be ready
          //links: this.encryptedLinkPath,
          isVisible: false,
        },
        {
          id: 14,
          name: "Longtime Owner Occupants Program",
          applicantInterested: false,
          phoneNumber: "215-686-9200",
          description: "message.loopDescription",
          website: "https://www.phila.gov/services/payments-assistance-taxes/payment-plans-and-assistance-programs/income-based-programs-for-residents/apply-for-the-longtime-owner-occupants-program-loop/",
          isVisible: false,
        },
        {
          id: 15,
          name: "Homestead Exemption Application",
          applicantInterested: false,
          phoneNumber: "215-686-8686",
          description: "message.homesteadDescription",
          website: "https://www.phila.gov/documents/homestead-exemption-application/",
          isVisible: false,
        },
      ],
    };
  },
  validations: {
    Name: { required },
    EmailAddress: { required },
    Address: {
      Street1: { required },
      City: { required },
      State: { required },
      Zip: { required },
    },
    Phone: { required },
    applicantInterestedList: { required }, //, minLength: 1
    // TripleRSelection: {
    //   required: requiredIf yes(function () {
    //     const rrrProgram = this.programs.find(
    //       i => i.name === "Restore Repair Renew Loan Program",
    //     );
    //     return rrrProgram.applicantInterested; //Determines whether applicantInterested is true based on function()
    //   }),
    // },
  },

  computed: {
    applicantInterestedList() {
      return this.programs
        .filter(program => program.applicantInterested)
        .map(program => program.name)
        .join(";");
    },
    applicantInterestedEmailFormat() {
      return this.programs
        .filter(program => program.applicantInterested)
        .map(program => ` <a href='${program.website}'>${program.name}</a>`);
    },
    debugProgramNames() {
      return this.programs.map(p => ({
        name: p.name,
        isVisible: p.isVisible,
        matchesInclude: ['Housing Counseling', 'Tangled Title', 'Neighborhood Energy Centers', 'Longtime Owner Occupants Program', 'Homestead Exemption Application'].includes(p.name),
      }));
    },
    //https://www.digitalocean.com/community/tutorials/4-uses-of-javascripts-arraymap-you-should-know
  },

  created(){
    this.checkForAMP(this.childPath, this.adultsPath, this.incomePath);
    this.checkForBSRP(this.childPath, this.adultsPath, this.incomePath);
    this.checkForBTL(this.childPath, this.adultsPath, this.incomePath);
    this.checkForHH(this.childPath, this.adultsPath, this.incomePath);
    this.checkForHC();
    this.checkForNEC(this.childPath, this.adultsPath, this.incomePath);
    this.checkForPEA();
    this.checkForPFH(this.childPath, this.adultsPath, this.incomePath);
    this.checkForRRR(this.childPath, this.adultsPath, this.incomePath);
    this.checkForTT(this.childPath, this.adultsPath, this.incomePath);
    this.checkForTK(this.childPath, this.adultsPath, this.incomePath);
    this.checkForUG(this.childPath, this.adultsPath, this.incomePath);
    this.checkForWAP(this.childPath, this.adultsPath, this.incomePath);
    this.checkForLOOP();
    this.checkForHEA();
  },
  
  methods: { 
    handleKeydown(event) {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        
        // Get the input element
        const input = event.target.tagName === 'LABEL' 
          ? document.getElementById(event.target.getAttribute('for'))
          : event.target;
        
        // Get the model name and value
        const modelName = input.name.replace('input-', '');
        const value = input.value;
        
        // Update the v-model directly
        this[modelName] = value;
        
        // Update DOM and trigger reactivity
        input.dispatchEvent(new Event('change', { bubbles: true }));
        input.checked = true;
        
        // Force validation
        if (this.$v[modelName]) {
          this.$v[modelName].$touch();
        }
        
        // Ensure reactivity for conditional rendering
        this.$forceUpdate();
      }
    },
    //C,A,I Children, Adults, Income Questions
    calcAmp(c, a, i) {
    //calcAmp is also used for BSRP)
      if (parseInt(c) + parseInt(a) == 1 && i <= 44300) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 2 && i <= 50600) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 3 && i <= 56950) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 4 && i <= 63250) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 5 && i <= 68300) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 6 && i <= 73400) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 7 && i <= 78450) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 8 && i <= 83500) {
        return true;
      }
      if (
        parseInt(c) + parseInt(a) > 8 &&
        i <= 83500 + 5050 * (parseInt(c) + parseInt(a) - 8)
      ) {
        return true;
      }
      return false;
    },

    calcRenew(c, a, i) {
    //Used to calculate eligibility for restore, repair, renew
      if (parseInt(c) + parseInt(a) == 1 && i <= 88500) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 2 && i <= 101200) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 3 && i <= 113850) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 4 && i <= 126500) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 5 && i <= 136600) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 6 && i <= 146750) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 7 && i <= 156850) {
        return true;
      }
      if (parseInt(c) + parseInt(a) >= 8 && i <= 167000) {
        return true;
      }
      if (
        parseInt(c) + parseInt(a) > 8 &&
        i <= 167000 + 10100 * (parseInt(c) + parseInt(a) - 8)
      ) {
        return true;
      }
      return false;
    },

    calcAMI(c, a, i, multipler) {
      if (parseInt(c) + parseInt(a) == 1 && i <= 73800 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 2 && i <= 84350 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 3 && i <= 94900 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 4 && i <= 105400 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 5 && i <= 113850 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 6 && i <= 122300 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 7 && i <= 130700 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 8 && i <= 139500 * multipler) {
        return true;
      }
      if (
        parseInt(c) + parseInt(a) > 8 &&
        i <= (139500 * multipler) + (8450 * multipler) * (parseInt(c) + parseInt(a) - 8)
      ) {
        return true;
      }
      return false;
    },

    fedPovertyCalc(c, a, i, multipler) {
      //Used to calculate eligibility for programs that base income off of federal poverty guidelines, with a multiplier for income. https://aspe.hhs.gov/poverty-guidelines
      if (parseInt(c) + parseInt(a) == 1 && i <= 15060 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 2 && i <= 20440 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 3 && i <= 25820 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 4 && i <= 31200 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 5 && i <= 36580 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 6 && i <= 41960 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 7 && i <= 47340 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 8 && i <= 52720 * multipler) {
        return true;
      }
      if (
        parseInt(c) + parseInt(a) > 8 &&
        i <= (52720 * multipler) + (5380 * multipler) * (parseInt(c) + parseInt(a) - 8)
      ) {
        return true;
      }
      return false;
    },
    checkForAMP(c,a,i) {
      const ampProgram = this.programs.find(p => p.name === "Adaptive Modification Program");
      if (!ampProgram) {
        return;
      }
      if (
        this.primaryResidencePath === "Yes" &&
        this.disabilityPath === "Yes" &&
        this.spropertyPath === "No" &&
        this.calcAMI(c, a, i, 0.6)
      ) {
        if (
          this.homeownerPath === "Homeowner" &&
          this.deedPath === "Deed" &&
          this.helpInHomePath !== "No"
        ) {
          this.resultsValid = true;
          ampProgram.isVisible = true;
        } else if (
          this.homeownerPath === "Renter" &&
          this.deedPath === "Lease" &&
          parseInt(this.yearsPath) >= 3 &&
          this.helpInHomePath !== "No"
        ) {
          this.resultsValid = true;
          ampProgram.isVisible = true;
        } else {
          ampProgram.isVisible = true;
        }
      } else {
        ampProgram.isVisible = false;
      }
    },

    checkForBSRP(c,a,i) {
      const bsrpProgram = this.programs.find(p => p.name === "Basic Systems Repair Program");
      const btlProgram = this.programs.find(p => p.name === "Built to Last");
      if (!bsrpProgram || !btlProgram) {
        return;
      }
      if (btlProgram.isVisible === true) {
        bsrpProgram.isVisible = false;
        return false;
      } else if(
        this.primaryResidencePath === "Yes" &&
        this.calcAMI(c, a, i, 0.6) === true &&
        this.spropertyPath === "No" &&
        this.deedPath === "Deed" &&
        this.homeownerPath === "Homeowner" &&
        this.heaterOrHotWaterPath === "No"
      ) {
        let tryCount = 0;
        const results = [
          this.roofLeakPath,
          this.plumbingProblemPath,
          this.structuralProblemPath,
          this.electricProblemPath,
          this.floorStairsPath,
        ];
        for (const result of results) {
          if (result !== "No") {
            tryCount++;
          }
        }
        if (tryCount >= 1 && tryCount <= 2) {
          this.resultsValid = true;
          bsrpProgram.isVisible = true;
        }
      } else {
        bsrpProgram.isVisible = false;
      }
    },

      
    // check first program elegibliity
    //this.programs[3].isVisible = true;
    checkForHH(c,a,i) {
      const hhProgram = this.programs.find(p => p.name === "Heater Hotline");
      const btlProgram = this.programs.find(p => p.name === "Built to Last");
      if (!hhProgram || !btlProgram) {
        return;
      }
      if (btlProgram.isVisible === true) {
        hhProgram.isVisible = false;
        return false;
      } else if (
        this.primaryResidencePath === "Yes" &&
        this.spropertyPath === "No" &&
        this.deedPath === "Deed" &&
        this.homeownerPath === "Homeowner" &&
        this.fedPovertyCalc(c, a, i, 1.5) === true
      ) { 
        if (this.heaterOrHotWaterPath !== "No") {
          this.resultsValid = true;
          hhProgram.isVisible = true;
        }
      } else if (this.heaterOrHotWaterPath === "No") {
        hhProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    // this.programs[12].isVisible = true;
    checkForWAP(c,a,i) {
      const wapProgram = this.programs.find(p => p.name === "Weatherization Assistance Program");
      const btlProgram = this.programs.find(p => p.name === "Built to Last");
      if (!wapProgram || !btlProgram) {
        return;
      }
      if (btlProgram.isVisible === true) {
        wapProgram.isVisible = false;
        return false;
      } else if (
        this.primaryResidencePath === "Yes" &&
        this.homeownerPath === "Homeowner" &&
        this.fedPovertyCalc(c, a, i, 2) &&
        this.billProblemPath !== "No"
      ) {
        this.resultsValid = true;
        wapProgram.isVisible = true;
      } else {
        wapProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    //this.programs[7].isVisible = true;
    checkForPFH(c,a,i) {
      const pfhProgram = this.programs.find(p => p.name === "Philly First Home");
      if (!pfhProgram) {
        return;
      }
      if (
        this.calcAMI(c, a, i, 1) === true &&
        this.homeownerPath !== "Homeowner" &&
        this.deedPath !== "Deed" &&
        this.spropertyPath === "No"
      ) {
        this.resultsValid = true;
        pfhProgram.isVisible = true;
      } else {
        pfhProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    //this.programs[4].isVisible = true;
    checkForHC() {
      const hcProgram = this.programs.find(p => p.name === "Housing Counseling");
      if (!hcProgram) {
        return;
      }
      if (this.primaryResidencePath === "Yes") {
        this.resultsValid = true;
        hcProgram.isVisible = true;
      } else {
        hcProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    //this.programs[9].isVisible = true;
    checkForTT(c,a,i) {
      const ttProgram = this.programs.find(p => p.name === "Tangled Title");
      if (!ttProgram) {
        return;
      }
      if (
        this.primaryResidencePath === "Yes" &&
        this.calcAMI(c, a, i, 0.8) === true &&
        this.deedPath === "Neither" &&
        this.tangleTitlePath === "Yes"
      ) {
        this.resultsValid = true;
        ttProgram.isVisible = true;
      } else {
        ttProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    //this.programs[8].isVisible = true;
    checkForRRR(c,a,i) {
      const rrrProgram = this.programs.find(p => p.name === "Restore Repair Renew Loan Program");
      if (!rrrProgram) {
        return;
      }
      if (
        this.primaryResidencePath === "Yes" &&
        this.calcRenew(c, a, i) === true &&
        i > 44000 &&
        this.homeownerPath === "Homeowner" &&
        this.deedPath === "Deed"
      ) {
        this.resultsValid = true;
        rrrProgram.isVisible = true;
      } else {
        rrrProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    //this.programs[11].isVisible = true;
    checkForUG(c,a,i) {
      const ugProgram = this.programs.find(p => p.name === "Utility Grant Program");
      if (!ugProgram) {
        return;
      }
      if (
        this.primaryResidencePath === "Yes" &&
        this.fedPovertyCalc(c, a, i, 1.75) === true
      ) {
        this.resultsValid = true;
        ugProgram.isVisible = true;
      } else {
        ugProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    //this.programs[10].isVisible = true;
    checkForTK(c,a,i) {
      const tkProgram = this.programs.find(p => p.name === "Turn the Key");
      if (!tkProgram) {
        return;
      }
      if (
        this.homeownerPath !== "Homeowner" &&
        this.deedPath !== "Deed" &&
        parseInt(this.yearsPath) >= 3 &&
        this.employeePath === "Yes" &&
        this.calcAMI(c, a, i, 1) === true
      ) {
        this.resultsValid = true;
        tkProgram.isVisible = true;
      } else {
        tkProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    //this.programs[6].isVisible = true;
    checkForPEA() {
      const peaProgram = this.programs.find(p => p.name === "PEA-Solarize Philly Program");
      if (!peaProgram) {
        return;
      }
      if (
        this.primaryResidencePath === "Yes" &&
        this.homeownerPath === "Homeowner" &&
        this.spropertyPath === "No"
      ) {
        if (this.solarPowerPath !== "No") {
          this.resultsValid = true;
          peaProgram.isVisible = true;
        }
      } else if (this.solarPowerPath === "No") {
        peaProgram.isVisible = false;
      }
    },


    // check first program elegibliity
    // this.programs[2].isVisible = true;
    checkForBTL(c,a,i) {
      const btlProgram = this.programs.find(p => p.name === "Built to Last");
      if (!btlProgram) {
        return;
      }
      if (
        this.primaryResidencePath === "Yes" &&
        this.homeownerPath === "Homeowner" &&
        this.spropertyPath === "No" &&
        this.calcAmp(c, a, i) === true
      ) {
        let tryCount = 0;
        const results = [
          this.roofLeakPath,
          this.plumbingProblemPath,
          this.structuralProblemPath,
          this.electricProblemPath,
          this.floorStairsPath,
        ];
        for (const result of results) {
          if (result !== "No") {
            tryCount++;
          }
        }
        if (tryCount >= 3) {
          this.resultsValid = true;
          btlProgram.isVisible = true;
        }
      } else {
        let programsMetConditionsCount = 0;

        if (
          this.primaryResidencePath === "Yes" &&
          this.homeownerPath === "Homeowner" &&
          this.fedPovertyCalc(c, a, i, 2) &&
          this.billProblemPath !== "No"
        ) {
          programsMetConditionsCount++;
        }

        if (
          this.primaryResidencePath === "Yes" &&
          this.spropertyPath === "No" &&
          this.deedPath === "Deed" &&
          this.homeownerPath === "Homeowner" &&
          this.fedPovertyCalc(c, a, i, 1.5) === true &&
          this.heaterOrHotWaterPath !== "No"
        ) {
          programsMetConditionsCount++;
        }

        if (
          this.primaryResidencePath === "Yes" &&
          this.calcAMI(c, a, i, 0.6) === true &&
          this.spropertyPath === "No" &&
          this.deedPath === "Deed" &&
          this.homeownerPath === "Homeowner" &&
          this.heaterOrHotWaterPath === "No"
        ) {
          let tryCount = 0;
          const results = [
            this.roofLeakPath,
            this.plumbingProblemPath,
            this.structuralProblemPath,
            this.electricProblemPath,
            this.floorStairsPath,
          ];
          for (const result of results) {
            if (result !== "No") {
              tryCount++;
            }
          }
          if (tryCount >= 1 && tryCount <= 2) {
            programsMetConditionsCount++;
          }
        }

        if (programsMetConditionsCount >= 2) {
          btlProgram.isVisible = true;
          this.resultsValid = true;
        } else {
          btlProgram.isVisible = false;
        }
      }
    },


    // check first program elegibliity
    //this.programs[5].isVisible = true;
    checkForNEC(c,a,i) {
      const necProgram = this.programs.find(p => p.name === "Neighborhood Energy Centers");
      if (!necProgram) {
        return;
      }
      if (
        this.primaryResidencePath == "Yes" &&
        this.homeownerPath == "Homeowner" || this.homeownerPath == "Renter" &&
        this.calcAmp(c, a, i) == true &&
        this.billProblemPath !== "No"
      ) {
        this.resultsValid = true;
        necProgram.isVisible = true;
      } else {
        necProgram.isVisible = false;
      }
    },

    // Checking for Longtime Owner Occupants Program
    checkForLOOP() {
      const loopProgram = this.programs.find(p => p.name === "Longtime Owner Occupants Program");
      // console.log("LOOP check starting");
      // console.log("homeownerPath value:", this.homeownerPath);
      // console.log("LOOP program object:", loopProgram);
      if (this.homeownerPath === "Homeowner" && loopProgram) {
        this.resultsValid = true;
        loopProgram.isVisible = true;
        //console.log("LOOP visibility set to:", loopProgram.isVisible);
      } else if (loopProgram) {
        loopProgram.isVisible = false;
      }
    },

    // Checking for Homestead Exemption Application
    checkForHEA() {
      const heaProgram = this.programs.find(p => p.name === "Homestead Exemption Application");
      // console.log("HEA check starting");
      // console.log("homeownerPath value:", this.homeownerPath);
      // console.log("HEA program object:", heaProgram);
      
      if (this.homeownerPath === "Homeowner" && heaProgram) {
        this.resultsValid = true;
        heaProgram.isVisible = true;
      } else if (heaProgram) {
        heaProgram.isVisible = false;
      }
    },

    checkEligibility(
      name,
      primaryResidencePath,
      c,
      a,
      i,
      disabilityPath,
      homeownerPath,
      spropertyPath,
      deedPath,
      yearsPath,
      employeePath,
      roofLeakPath,
      electricProblemPath,
      plumbingProblemPath,
      structuralProblemPath,
      floorStairsPath,
      heaterOrHotWaterPath,
      helpInHomePath,
      tangleTitlePath,
      solarPowerPath,
      billProblemPath,
      shiaEmailPath,
    ) {
      this.checkForNEC(c,a,i);
      this.checkForBTL(c,a,i);
      this.checkForHH(c,a,i);
      this.checkForPFH(c,a,i);
      this.checkForRRR(c,a,i);
      this.checkForTT(c,a,i);
      this.checkForTK(c,a,i);
      this.checkForUG(c,a,i);
      this.checkForWAP(c,a,i);
      this.checkForBSRP(c,a,i);
      this.checkForAMP(c,a,i);
      this.checkForHC();
      this.checkForPEA();
      this.checkForLOOP();
      this.checkForHEA();
      //console.log("Name:", name);
      //console.log("Primary Residence Path:", primaryResidencePath);
      //console.log("C:", c);
      //console.log("A:", a);
      //console.log("I:", i);
      //console.log("Disability Path:", disabilityPath);
      //console.log("Homeowner Path:", homeownerPath);
      //console.log("Sproperty Path:", spropertyPath);
      //console.log("Deed Path:", deedPath);
      //console.log("Years Path:", yearsPath);
      //console.log("Employee Path:", employeePath);
      //console.log("Roof Leak Path:", roofLeakPath);
      //console.log("Electric Problem Path:", electricProblemPath);
      //console.log("Plumbing Problem Path:", plumbingProblemPath);
      //console.log("Structural Problem Path:", structuralProblemPath);
      //console.log("Floor Stairs Path:", floorStairsPath);
      //console.log("Heater Or Hot Water Path:", heaterOrHotWaterPath);
      //console.log("Help In Home Path:", helpInHomePath);
      //console.log("Tangle Title Path:", tangleTitlePath);
      //console.log("Solar Power Path:", solarPowerPath);
      //console.log("Bill Problem Path:", billProblemPath);
      
      if (
        //used to display no qualifications if page is refreshed
        name == null &&
        primaryResidencePath == null &&
        c == null &&
        a == null &&
        i == null &&
        disabilityPath == null &&
        homeownerPath == null &&
        spropertyPath == null &&
        deedPath == null &&
        yearsPath == null &&
        employeePath == null &&
        roofLeakPath == null &&
        electricProblemPath == null &&
        plumbingProblemPath == null &&
        structuralProblemPath == null &&
        floorStairsPath == null &&
        heaterOrHotWaterPath == null &&
        helpInHomePath == null &&
        tangleTitlePath == null &&
        solarPowerPath == null &&
        billProblemPath == null &&
        shiaEmailPath == null
      ) {
        this.resultsValid = false;
        return false;
      }
    }, 

    handleSubmit() {
      var vData = this;
      //this.$v.$touch();
      if (this.$v.$invalid == true) {
        this.submitStatus = "ERROR";
        // return this.$alert(
        //   "Please enter all required information and try again.",
        // );
      }

      //   this.$v.$touch();
      //   this.submitStatus = "PENDING";
      //   const dataObject = buildApplicationDataObject(vData);
      //   const xmlDataObject = dataObjectToXml(dataObject);
      //   const requestObj = {
      //     table: "bqj7i59gi",
      //     action: "API_AddRecord",
      //     data: xmlDataObject,
      //     // fieldToEmail: {
      //     //   //test email via Lambda
      //     //   lambdaEmail: vData.EmailAddress,
      //     //   lambdaIncome: vData.incomePath,
      //     //   lambdaName: vData.Name,
      //     //   lambdaPrograms: vData.applicantInterestedEmailFormat,
      //     // },
      //   };
      //   //console.log(requestObj);
      //   //debugger;  //disabled debugger for alerts
      //   axios
      //     .post(
      //       "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Discover-Benefits-QuickBase-Request-Proxy",
      //       requestObj,
      //     )

      //     .then(() => {
      const selectedPrograms = this.programs.filter(program => program.applicantInterested);
      map(({ id, name, phoneNumber, description, website, links }) => ({ id, name, phoneNumber, description, website, links }));
      if (selectedPrograms.length === 0) {
        alert("Please select at least one program.");
        return;
      }
      this.$v.$touch();
      this.submitStatus = "OK";
      this.$router.push({
        name: "Results",
        params: { selectedPrograms,
          adultsPath: this.adultsPath,
          childPath: this.childPath,
          incomePath: this.incomePath,
          yearsPath: this.yearsPath,
          disabilityPath: this.disabilityPath,
          primaryResidencePath: this.primaryResidencePath,
          homeownerPath: this.homeownerPath,
          spropertyPath: this.spropertyPath,
          deedPath: this.deedPath,
          referralPath: this.referralPath,
          employeePath: this.employeePath,
          roofLeakPath: this.roofLeakPath,
          electricProblemPath: this.electricProblemPath,
          plumbingProblemPath: this.plumbingProblemPath,
          structuralProblemPath: this.structuralProblemPath,
          floorStairsPath: this.floorStairsPath,
          heaterOrHotWaterPath: this.heaterOrHotWaterPath,
          helpInHomePath: this.helpInHomePath,
          tangleTitlePath: this.tangleTitlePath,
          solarPowerPath: this.solarPowerPath,
          billProblemPath: this.billProblemPath,
        },
      },
      )
        .catch(() => {
          vData.submitStatus = "POST_ERROR";
        });
    },
  },
};
</script>

<style scoped>
.checkbox-input {
  cursor: pointer;
}

.checkbox-input:focus {
  outline: 2px solid #0f4d90;
  outline-offset: 2px;
}
</style>
