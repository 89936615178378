/* eslint-disable no-undef */
export const delay = (function () {
  var timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();


export const buildApplicationDataObject = (vData) => {
  const dataObject = {
    qdbapi: {
      udata: "DiscoverHousing_QuestionnaireResponses",
      field: [
        // Tenant Info
        {
          _fid: "6",
          __text: vData.adultsPath,
        },
        {
          _fid: "7",
          __text: vData.childPath,
        },
        {
          _fid: "8",
          __text: vData.incomePath,
        },
        {
          _fid: "9",
          __text: vData.yearsPath,
        },
        {
          _fid: "10",
          __text: vData.disabilityPath,
        },
        {
          _fid: "11",
          __text: vData.primaryResidencePath,
        },
        {
          _fid: "12",
          __text: vData.homeownerPath,
        },
        {
          _fid: "13",
          __text: vData.spropertyPath,
        },
        {
          _fid: "14",
          __text: vData.deedPath,
        },
        {
          _fid: "15",
          __text: vData.applicantInterestedList,
        },
        {
          _fid: "16",
          __text: vData.Name,
        },
        {
          _fid: "18",
          __text: vData.Address.Street1,
        },
        {
          _fid: "19",
          __text: vData.Address.Street2,
        },
        {
          _fid: "20",
          __text: vData.Address.City,
        },
        {
          _fid: "21",
          __text: vData.Address.State,
        },
        {
          _fid: "22",
          __text: vData.Address.Zip,
        },
        {
          _fid: "24",
          __text: vData.Phone,
        },
        {
          _fid: "25",
          __text: vData.EmailAddress,
        },
        // {
        //   _fid: "26",
        //   __text: vData.applicantInterestedEmailFormat, //check if needed
        // },
        {
          _fid: "31",
          __text: vData.referralPath,
        },
        {
          _fid: "32",
          __text: vData.TripleRSelection,
        },
        {
          _fid: "38",
          __text: vData.employeePath,
        },
        {
          _fid: "39",
          __text: vData.roofLeakPath,
        },
        {
          _fid: "40",
          __text: vData.electricProblemPath,
        },
        {
          _fid: "41",
          __text: vData.plumbingProblemPath,
        },
        {
          _fid: "42",
          __text: vData.structuralProblemPath,
        },
        {
          _fid: "43",
          __text: vData.floorStairsPath,
        },
        {
          _fid: "44",
          __text: vData.heaterOrHotWaterPath,
        },
        {
          _fid: "45",
          __text: vData.helpInHomePath,
        },
        {
          _fid: "46",
          __text: vData.tangleTitlePath,
        },
        {
          _fid: "47",
          __text: vData.solarPowerPath,
        },
        {
          _fid: "48",
          __text: vData.billProblemPath,
        },
        {
          _fid: "49",
          _text: vData.shiaEmail,
        },
      ],
    },
  };
  return dataObject;
};

export const dataObjectToXml = (dataObject) => {
  const options = { compact: true, ignoreComment: true, spaces: 4 };
  var xml2Json = new X2JS();
  return xml2Json.json2xml_str(dataObject, options);
};

export const getPropertyValueOrDefault = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
