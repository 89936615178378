<template>
  <router-view />
</template>

<script>
export default {
  name: "OnePhillyFrontDoor",
};
</script>

<style>
body {
  display: flex;
  flex-direction: column;
}
#app-container {
  flex: 1 0 auto;
  padding: 10px;
}
.file-upload-button {
  margin-bottom: 1.14286rem;
}
.sticky-header-width {
  padding-bottom: 10px;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

footer,
header {
  flex-shrink: 0;
}
@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  animation: 1s blink infinite;
}
.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}
.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}
.multiselect__tag,
.multiselect__option--highlight {
  background: #25cef7 !important;
}
.multiselect__tags {
  background: #f0f0f0 !important;
}

.grid-x,
.grid-y {
  width: 100%;
}
</style>
