<template>
  <div
    class="background-container"
    role="main"
    aria-label="Housing Benefits Application Form"
  >
    <div
      class="left-img-container"
      role="presentation"
    >
      <img
        class="left-img"
        src="../../public/Mayor Cherelle Parker Official Photo b.jpg"
        alt="Portrait of Mayor Cherelle Parker"
        aria-label="Official portrait of Philadelphia Mayor Cherelle Parker"
      >
    </div>
    <!-- Form Section -->
    <div
      class="form-container"
      role="form"
      aria-label="Housing Benefits Application"
    >
      <form
        id="applicationSubmission"
        aria-label="Application Form for Housing Benefits"
        @submit.prevent="handleSubmit"
      >
        <div class="grid-container">
          <div class="grid-y grid-margin-x">
            <div class="back-link">
              <a
                href="/"
                class="back-text"
                style="color: #0f4d90; text-decoration: none;"
                aria-label="Return to Landing Page"
              >
                &lt; Back
              </a>
            </div>
            <h1
              role="heading"
              aria-level="1"
            >
              <b>{{ $t("message.homeTitle") }}</b>
            </h1>
            <h3
              role="heading"
              aria-level="2"
            >
              <b>{{ $t("message.homeQuestionLabel") }}:</b>
            </h3>
            <p>{{ $t("message.homeDescriptionText") }}</p>

            <!-- Programs List -->
            <div
              class="apps"
              role="navigation"
              aria-label="Available Housing Programs"
            >
              <hr aria-hidden="true">
              <a
                href="https://phdcphila.org/residents/home-repair/adaptive-modifications-program/"
                class="is-external-link is-small"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Adaptive Modification Program - Opens in new tab"
              >Adaptive Modification Program <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://phdcphila.org/residents/home-repair/basic-systems-repair-program/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Basic Systems Repair Program - Opens in new tab"
              >Basic Systems Repair Program <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://philaenergy.org/programs-initiatives/built-to-last/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Built to Last - Opens in new tab"
              >Built to Last <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <!-- Not working -->
              <a
                href="https://www.ecasavesenergy.org/heater-hotline"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Heater Hotline - Opens in new tab"
              >Heater Hotline <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://www.phila.gov/departments/division-of-housing-and-community-development/neighborhood-resources/housing-counseling/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Housing Counseling - Opens in new tab"
              >Housing Counseling <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://www.phila.gov/departments/division-of-housing-and-community-development/neighborhood-resources/neighborhood-energy-centers-necs/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Neighborhood Energy Centers - Opens in new tab"
              >Neighborhood Energy Centers <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://solarizephilly.org/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="PEA-Solarize Philly Program - Opens in new tab"
              >PEA-Solarize Philly Program <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://phdcphila.org/residents/homebuyers-and-renters/philly-first-home/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Philly First Home - Opens in new tab"
              >Philly First Home <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://phdcphila.org/residents/home-repair/restore-repair-renew"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Restore Repair Renew Loan Program - Opens in new tab"
              >Restore Repair Renew Loan Program <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://www.phillyvip.org/tangled-title-fund/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Tangled Title - Opens in new tab"
              >Tangled Title <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://phdcphila.org/residents-and-landlords/homebuyers-and-renters/turn-the-key/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Turn the Key - Opens in new tab"
              >Turn the Key <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://dced.pa.gov/programs/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Utility Grant Program - Opens in new tab"
              >Utility Grant Program <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://dced.pa.gov/programs/weatherization-assistance-program-wap/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Weatherization Assistance Program - Opens in new tab"
              >Weatherization Assistance Program <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://www.phila.gov/services/payments-assistance-taxes/payment-plans-and-assistance-programs/income-based-programs-for-residents/apply-for-the-longtime-owner-occupants-program-loop/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Longtime Owner Occupants Program- Opens in new tab"
              >Longtime Owner Occupants Program <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>

              <a
                href="https://www.phila.gov/documents/homestead-exemption-application/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Homestead Exemption Application - Opens in new tab"
              >Homestead Exemption Application <i
                class="fa fa-external-link"
                aria-hidden="true"
              /></a>
            </div>

            <p style="margin-top: 14px;">
              {{ $t("message.homeDescriptionText2") }}
            </p>
            <br>
            <p><em>{{ $t("message.comingSoon") }}</em></p>
            <br>
            <br>

            <!-- Form Fields -->
            <div class="cell">
              <p
                id="adults-help-text"
                for="input-adults-in-household"
                class="asterisk-label"
                aria-required="true"
              >
                {{
                  $t("message.homeAdultsQuestion")
                }}
              </p>
              <div
                v-if="$v.AdultsInHousehold.$error"
                class="love-park-red"
                aria-live="polite"
              >
                {{ $t("message.homeAdultsError") }}
              </div>
              <input
                id="input-adults-in-household"
                v-model.trim="AdultsInHousehold"
                name="input-adults-in-household"
                type="number"
                min="0"
                aria-describedby="adults-help-text"
                aria-label="input-adults-in-household"
              >
            </div>

            <div class="cell">
              <p
                for="input-children-in-household"
                class="
              asterisk-label"
              >
                {{
                  $t("message.homeChildrenQuestion",)
                }}
              </p>
              <div
                v-if="$v.ChildrenInHousehold.$error"
                class="love-park-red"
              >
                {{ $t("message.homeChildrenError") }}
              </div>
              <input
                id="input-children-in-household"
                v-model.trim="ChildrenInHousehold"
                name="input-children-in-household"
                type="number"
                min="0"
                aria-label="input-children-in-household"
              >
            </div>

            <div class="cell">
              <p
                for="input-household-income"
                class="
              asterisk-label"
              >
                {{
                  $t("message.homeHouseholdIncome")
                }}
              </p>
              <div
                v-if="$v.HouseholdIncome.$error"
                class="love-park-red"
              >
                {{ $t("message.homeHouseholdIncomeError") }}
              </div>
              <input
                id="input-household-income"
                v-model.trim="HouseholdIncome"
                name="input-household-income"
                type="number"
                min="0"
                aria-label="input-household-income"
              >
            </div>

            <div class="cell">
              <p
                for="input-years-of-residence"
                class="
              asterisk-label"
              >
                {{
                  $t("message.homeYearsOfResidence")
                }}
              </p>
              <div
                v-if="$v.YearsOfResidence.$error"
                class="love-park-red"
              >
                {{ $t("message.homeYearsOfResidenceError") }}
              </div>
              <input
                id="input-years-of-residence"
                v-model.trim="YearsOfResidence"
                name="input-years-of-residence"
                type="number"
                min="0"
                aria-label="input-years-of-residence"
              >
            </div>

            <div class="cell">
              <p
                for="input-shia-email"
                class="
              asterisk-label"
              >
                {{
                  $t("message.shiaEmail")
                }}
              </p>
              <div
                v-if="$v.ShiaEmail.$error"
                class="love-park-red"
              >
                {{ $t("message.shiaEmailError") }}
              </div>
              <input
                id="input-shia-email"
                v-model.trim="ShiaEmail"
                name="input-shia-email"
                type="email"
                aria-label="input-shia-email"
              >
            </div>

            <!-- Radio Buttons -->
            <!-- Primary Residence -->
            <div class="cell">
              <fieldset
                role="radiogroup"
                aria-labelledby="primary-residence-label"
              >
                <p id="primary-residence-label">
                  {{ $t("message.primaryResidence") }}<span
                    class="asterisk-label"
                    aria-label="required"
                  />
                </p>
                <div
                  v-if="$v.PrimaryResidence.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.primaryResidenceError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-primary-residence-yes"
                    v-model.trim="PrimaryResidence"
                    type="radio"
                    name="input-primary-residence"
                    value="Yes"
                    @keydown="handleKeydown"                    
                  >
                  <label
                    for="input-primary-residence-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-primary-residence-no"
                    v-model.trim="PrimaryResidence"
                    type="radio"
                    name="input-primary-residence"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-primary-residence-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>
            </div>

            <!-- Employee Question -->
            <div class="cell">
              <fieldset
                role="radiogroup"
                aria-labelledby="employee-question-label"
              >
                <p id="employee-question-label">
                  {{ $t("message.employeeQuestion") }}<span
                    class="asterisk-label"
                    aria-label="required"
                  />
                </p>
                <div
                  v-if="$v.EmployeeStatus.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.employeeQuestionError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-employee-question-yes"
                    v-model.trim="EmployeeStatus"
                    type="radio"
                    name="input-employee-question"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-employee-question-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-employee-question-no"
                    v-model.trim="EmployeeStatus"
                    type="radio"
                    name="input-employee-question"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-employee-question-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>
            </div>

            <!-- Home Deed -->
            <div class="cell">
              <fieldset
                role="radiogroup"
                aria-labelledby="home-deed-label"
              >
                <p id="home-deed-label">
                  {{ $t("message.homeDeed") }}<span
                    class="asterisk-label"
                    aria-label="required"
                  />
                </p>
                <div
                  v-if="$v.NameOnDeed.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.homeDeedError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-name-on-deed-deed"
                    v-model.trim="NameOnDeed"
                    type="radio"
                    name="input-name-on-deed"
                    value="Deed"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-name-on-deed-deed"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.deedLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-name-on-deed-lease"
                    v-model.trim="NameOnDeed"
                    type="radio"
                    name="input-name-on-deed"
                    value="Lease"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-name-on-deed-lease"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.leaseLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-name-on-deed-neither"
                    v-model.trim="NameOnDeed"
                    type="radio"
                    name="input-name-on-deed"
                    value="Neither"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-name-on-deed-neither"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.neitherLabel") }}</label>
                </div>
              </fieldset>
            </div>

            <!-- Homeowner Option -->
            <div class="cell">
              <fieldset
                role="radiogroup"
                aria-labelledby="homeowner-option-label"
              >
                <p id="homeowner-option-label">
                  {{ $t("message.homeHomeownerLabel") }}<span
                    class="asterisk-label"
                    aria-label="required"
                  />
                </p>
                <div
                  v-if="$v.HomeownerOption.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.homeHomeownerError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-homeowner-option-homeowner"
                    v-model.trim="HomeownerOption"
                    type="radio"
                    name="input-homeowner-option"
                    value="Homeowner"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-homeowner-option-homeowner"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.homeownerLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-homeowner-option-renter"
                    v-model.trim="HomeownerOption"
                    type="radio"
                    name="input-homeowner-option"
                    value="Renter"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-homeowner-option-renter"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.renterLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-homeowner-option-neither"
                    v-model.trim="HomeownerOption"
                    type="radio"
                    name="input-homeowner-option"
                    value="Neither"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-homeowner-option-neither"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.neitherLabel") }}</label>
                </div>
              </fieldset>
            </div>

            <!-- Second Property -->
            <div class="cell">
              <fieldset
                role="radiogroup"
                aria-labelledby="home-additional-label"
              >
                <p id="home-additional-label">
                  {{ $t("message.homeAdditionalProperties") }}<span
                    class="asterisk-label"
                    aria-label="required"
                  />
                </p>
                <div
                  v-if="$v.SecondProperty.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.homeAdditionalPropertiesError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-second-property-yes"
                    v-model.trim="SecondProperty"
                    type="radio"
                    name="input-second-property"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-second-property-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-second-property-no"
                    v-model.trim="SecondProperty"
                    type="radio"
                    name="input-second-property"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-second-property-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>
            </div>

            <!-- Disability Option -->
            <div class="cell">
              <fieldset
                role="radiogroup"
                aria-labelledby="home-disability-label"
              >
                <p id="home-disability-label">
                  {{ $t("message.homeDisability") }}<span
                    class="asterisk-label"
                    aria-label="required"
                  />
                </p>
                <div
                  v-if="$v.DisabilityOption.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.homeDisabilityError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-disability-option-yes"
                    v-model.trim="DisabilityOption"
                    type="radio"
                    name="input-disability-option"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-disability-option-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-disability-option-no"
                    v-model.trim="DisabilityOption"
                    type="radio"
                    name="input-disability-option"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-disability-option-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>
            </div>

            <!-- Secondary Questions -->
            <div
              v-if="PrimaryResidence === 'Yes' && HomeownerOption === 'Homeowner'"
              class="cell"
            >
              <!-- Roof Leak -->
              <fieldset
                role="radiogroup"
                aria-labelledby="roof-leak-label"
              >
                <p id="roof-leak-label">
                  {{ $t("message.roofLeak") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.RoofLeak.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.roofLeakError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-roof-leak-yes"
                    v-model.trim="RoofLeak"
                    type="radio"
                    name="input-roof-leak"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-roof-leak-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-roof-leak-no"
                    v-model.trim="RoofLeak"
                    type="radio"
                    name="input-roof-leak"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-roof-leak-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>

              <!-- Electric Problem -->
              <fieldset
                role="radiogroup"
                aria-labelledby="electric-problem-label"
              >
                <p id="electric-problem-label">
                  {{ $t("message.electricProblem") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.ElectricProblem.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.electricProblemError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-electric-problem-yes"
                    v-model.trim="ElectricProblem"
                    type="radio"
                    name="input-electric-problem"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-electric-problem-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-electric-problem-no"
                    v-model.trim="ElectricProblem"
                    type="radio"
                    name="input-electric-problem"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-electric-problem-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>

              <!-- Plumbing Problem -->
              <fieldset
                role="radiogroup"
                aria-labelledby="plumbing-problem-label"
              >
                <p id="plumbing-problem-label">
                  {{ $t("message.plumbingProblem") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.PlumbingProblem.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.plumbingProblemError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-plumbing-problem-yes"
                    v-model.trim="PlumbingProblem"
                    type="radio"
                    name="input-plumbing-problem"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-plumbing-problem-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-plumbing-problem-no"
                    v-model.trim="PlumbingProblem"
                    type="radio"
                    name="input-plumbing-problem"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-plumbing-problem-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>

              <!-- Structural Problem -->
              <fieldset
                role="radiogroup"
                aria-labelledby="structural-problem-label"
              >
                <p id="structural-problem-label">
                  {{ $t("message.structuralProblem") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.StructuralProblem.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.structuralProblemError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-structural-problem-yes"
                    v-model.trim="StructuralProblem"
                    type="radio"
                    name="input-structural-problem"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-structural-problem-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-structural-problem-no"
                    v-model.trim="StructuralProblem"
                    type="radio"
                    name="input-structural-problem"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-structural-problem-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>

              <!-- Floor Stairs -->
              <fieldset
                role="radiogroup"
                aria-labelledby="floor-stairs-label"
              >
                <p id="floor-stairs-label">
                  {{ $t("message.floorStairs") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.FloorStairs.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.floorStairsError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-floor-stairs-yes"
                    v-model.trim="FloorStairs"
                    type="radio"
                    name="input-floor-stairs"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-floor-stairs-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-floor-stairs-no"
                    v-model.trim="FloorStairs"
                    type="radio"
                    name="input-floor-stairs"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-floor-stairs-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>
              <br>

              <!-- Heater or Hot Water -->
              <fieldset
                role="radiogroup"
                aria-labelledby="heater-or-hot-water-label"
              >
                <p id="heater-or-hot-water-label">
                  {{ $t("message.heaterOrHotWater") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.HeaterOrHotWater.$error"
                  class="love-park-red"
                >
                  {{ $t("message.heaterOrHotWaterError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-heater-or-hot-water-yes"
                    v-model.trim="HeaterOrHotWater"
                    type="radio"
                    name="input-heater-or-hot-water"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-heater-or-hot-water-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-heater-or-hot-water-no"
                    v-model.trim="HeaterOrHotWater"
                    type="radio"
                    name="input-heater-or-hot-water"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-heater-or-hot-water-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>

              <!-- Help in Home -->
              <fieldset
                role="radiogroup"
                aria-labelledby="help-in-home-label"
              >
                <p id="help-in-home-label">
                  {{ $t("message.helpInHome") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.HelpInHome.$error"
                  class="love-park-red"
                >
                  {{ $t("message.helpInHomeError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-help-in-home-yes"
                    v-model.trim="HelpInHome"
                    type="radio"
                    name="input-help-in-home"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-help-in-home-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-help-in-home-no"
                    v-model.trim="HelpInHome"
                    type="radio"
                    name="input-help-in-home"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-help-in-home-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>

              <!-- Tangle Title -->
              <fieldset
                role="radiogroup"
                aria-labelledby="tangle-title-label"
              >
                <p id="tangle-title-label">
                  {{ $t("message.tangleTitle") }}<span class="asterisk-label" />
                </p>
                <!-- Info dropdown -->
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-info"
                    aria-live="polite"
                  >
                    Info
                  </button>
                  <button
                    type="button"
                    class="btn btn-info dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown"
                  />
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item"
                      href="#"
                    >{{ $t("message.tangledTitleDescription2") }}</a>
                  </div>
                </div>
                <div
                  v-if="$v.TangleTitle.$error"
                  class="love-park-red"
                >
                  {{ $t("message.tangleTitleError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-tangle-title-yes"
                    v-model.trim="TangleTitle"
                    type="radio"
                    name="input-tangle-title"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-tangle-title-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-tangle-title-no"
                    v-model.trim="TangleTitle"
                    type="radio"
                    name="input-tangle-title"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-tangle-title-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>

              <!-- Solar Power -->
              <fieldset
                role="radiogroup"
                aria-labelledby="solar-power-label"
              >
                <p id="solar-power-label">
                  {{ $t("message.solarPower") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.SolarPower.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.solarPowerError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-solar-power-yes"
                    v-model.trim="SolarPower"
                    type="radio"
                    name="input-solar-power"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-solar-power-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-solar-power-no"
                    v-model.trim="SolarPower"
                    type="radio"
                    name="input-solar-power"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-solar-power-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>

              <!-- Bill Problem -->
              <fieldset
                role="radiogroup"
                aria-labelledby="bill-problem-label"
              >
                <p id="bill-problem-label">
                  {{ $t("message.billProblem") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.BillProblem.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.billProblemError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-bill-problem-yes"
                    v-model.trim="BillProblem"
                    type="radio"
                    name="input-bill-problem"
                    value="Yes"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-bill-problem-yes"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.yesLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-bill-problem-no"
                    v-model.trim="BillProblem"
                    type="radio"
                    name="input-bill-problem"
                    value="No"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-bill-problem-no"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.noLabel") }}</label>
                </div>
              </fieldset>
            </div>

            <!-- Referral Type -->
            <div class="cell">
              <fieldset
                role="radiogroup"
                aria-labelledby="home-referral-label"
              >
                <p id="home-referral-label">
                  {{ $t("message.homeReferralQuestion") }}<span class="asterisk-label" />
                </p>
                <div
                  v-if="$v.referralType.$error"
                  class="love-park-red"
                  aria-live="polite"
                >
                  {{ $t("message.homeReferralQuestionError") }}
                </div>
                <div class="radio-option">
                  <input
                    id="input-referralType-option-WoM"
                    v-model.trim="referralType"
                    type="radio"
                    name="input-referralType-option"
                    value="Word of Mouth"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-referralType-option-WoM"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.wordOfMouthLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-referralType-option-sm"
                    v-model.trim="referralType"
                    type="radio"
                    name="input-referralType-option"
                    value="Social Media"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-referralType-option-sm"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.socialMediaLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-referralType-option-comm"
                    v-model.trim="referralType"
                    type="radio"
                    name="input-referralType-option"
                    value="Community Partner"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-referralType-option-comm"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.communityPartnerLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-referralType-option-cityC"
                    v-model.trim="referralType"
                    type="radio"
                    name="input-referralType-option"
                    value="City Council Office"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-referralType-option-cityC"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.cityCouncilLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-referralType-option-news"
                    v-model.trim="referralType"
                    type="radio"
                    name="input-referralType-option"
                    value="Newspaper"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-referralType-option-news"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.newspaperLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-referralType-option-email"
                    v-model.trim="referralType"
                    type="radio"
                    name="input-referralType-option"
                    value="Email"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-referralType-option-email"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.emailOptionLabel") }}</label>
                </div>
                <div class="radio-option">
                  <input
                    id="input-referralType-option-other"
                    v-model.trim="referralType"
                    type="radio"
                    name="input-referralType-option"
                    value="Other"
                    @keydown="handleKeydown"
                  >
                  <label
                    for="input-referralType-option-other"
                    tabindex="0"
                    @keydown="handleKeydown"
                  >{{ $t("message.otherLabel") }}</label>
                </div>
              </fieldset>
            </div>

            <!-- SPLASH STARTS HERE -->
            <!-- <SplashPage v-if="showSplash" @close-splash="closeSplash" /> -->

            <br>
            <div>
              <i>{{ $t("message.homeSubmissionLabel") }}</i>
            </div>
            <div class="grid-x grid-padding-x align-left">
              <div
                class="cell medium-4"
                style="margin-top: 16px;"
              >
                <button class="button">
                  {{ $t("message.homeSubmit") }}
                </button>
              </div>
            </div>
            <p
              v-if="submitStatus === 'OK'"
              class="typo__p"
              aria-live="polite"
            >
              {{ $t("message.homeThankYou") }}
            </p>
            <p
              v-if="submitStatus === 'ERROR'"
              class="typo__p"
              aria-live="assertive"
            >
              {{ $t("message.homeApplicationError") }}
            </p>
            <p
              v-if="submitStatus === 'PENDING'"
              class="typo__p"
            >
              {{ $t("message.homeSubmittingLabel") }}
            </p>
          </div>
        </div>
      </form>
    </div>
    <div class="right-img-container">
      <img
        class="right-img"
        src="../../public/front door.jpg"
        alt="Open front door"
        aria-label="Welcoming open front door symbolizing housing accessibility"
      >
    </div>
    <footer
      id="global-footer"
      style="margin-top: 8px; margin-bottom: 32px"
      data-swiftype-index="false"
      class="site-footer"
    >
      <!-- Your footer content goes here -->
    </footer>
  </div>
</template>

<script>
//import SplashPage from './SplashPage.vue';
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
//import axios from "axios";
//import { withParams } from 'vuelidate/lib';
const money = value => /^\$?\d+(,\d{3})*\.?[0-9]?[0-9]?$/.test(value);

// const requiredIfHomeowner = (value) =>{
//   return withParams(
//     { type: 'requiredIfHomeowner' },
//     function (value) {
//       if (this.PrimaryResidence === 'Yes' && this.HomeownerOption === 'Homeowner') {
//         return required(value);
//       }
//       return true;
//     },
//   )(value);
// };

export default {
  name: "HomePage",
  components: {
  },
  data() {
    return {
      showSplash: true, // Initialize showSplash
      submitStatus: null,
      AdultsInHousehold: null,
      ChildrenInHousehold: null,
      HouseholdIncome: null,
      YearsOfResidence: null,
      DisabilityOption: null,
      HomeownerOption: null,
      SecondProperty: null,
      PrimaryResidence: null,
      NameOnDeed: null,
      referralType: null,
      AdditionalHouseholds: null /*edited*/,
      EmployeeStatus: null,
      RoofLeak: null,
      ElectricProblem: null,
      PlumbingProblem: null,
      StructuralProblem: null,
      FloorStairs: null,
      HeaterOrHotWater: null,
      HelpInHome: null,
      TangleTitle: null,
      SolarPower: null,
      BillProblem: null,
      ShiaEmail: null,
      encryptedLink: null,
    };
  },
  validations: {
    AdultsInHousehold: { required } /*my variables start here*/,
    ChildrenInHousehold: { required },
    HouseholdIncome: { required, money },
    YearsOfResidence: { required },
    DisabilityOption: { required },
    HomeownerOption: { required },
    SecondProperty: { required },
    PrimaryResidence: { required },
    NameOnDeed: { required },
    referralType: { required },
    EmployeeStatus: { required },
    ShiaEmail: { required },
    RoofLeak: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.RoofLeak);
        }
        return true;

      },
    },
    ElectricProblem: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.ElectricProblem);
        }
        return true;
      },
    },
    PlumbingProblem: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.PlumbingProblem);
        }
        return true;
      },
    },
    StructuralProblem: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.StructuralProblem);
        }
        return true;
      },
    },
    FloorStairs: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.FloorStairs);
        }
        return true;
      },
    },
    HeaterOrHotWater: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.HeaterOrHotWater);
        }
        return true;
      },
    },
    HelpInHome: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.HelpInHome);
        }
        return true;
      },
    },
    TangleTitle: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.TangleTitle);
        }
        return true;
      },
    },
    SolarPower: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.SolarPower);
        }
        return true;
      },
    },
    BillProblem: {
      required() {
        if (this.PrimaryResidence === "Yes" && this.HomeownerOption === "Homeowner") {
          return required(this.BillProblem);
        }
        return true;
      },
    },
  },
  computed: {
    shouldShowSecondaryQuestions() {
      return this.PrimaryResidence === 'Yes' && this.HomeownerOption === 'Homeowner';
    },
  },
  methods: {
    //Select Radio button w/ enter or space key
    handleKeydown(event) {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        
        // Get the input element
        const input = event.target.tagName === 'LABEL' 
          ? document.getElementById(event.target.getAttribute('for'))
          : event.target;
        
        // Get the model name and value
        const modelName = input.name.replace('input-', '');
        const value = input.value;
        
        // Update the v-model directly
        this[modelName] = value;
        
        // Update DOM and trigger reactivity
        input.dispatchEvent(new Event('change', { bubbles: true }));
        input.checked = true;
        
        // Force validation
        if (this.$v[modelName]) {
          this.$v[modelName].$touch();
        }
        
        // Ensure reactivity for conditional rendering
        this.$forceUpdate();
      }
    },
    //  async 
    async handleSubmit() {
      var vData = this;
      this.$v.$touch();
      if (this.$v.$invalid && this.$i18n.locale == "English") {
        this.submitStatus = "ERROR";
        this.$alert(
          "All questions are required. Please ensure all questions have been answered before sumbitting.",
        );
      } else if (this.$v.$invalid && this.$i18n.locale == "Spanish") {
        this.submitStatus = "ERROR";
        this.$alert(
          "Debe responder a todas las preguntas. Favor de asegurarse de que contestó todas las preguntas antes de enviar.",
        );
      } else {
        //else for error handing
        this.submitStatus = "OK";

        const requestObj = {
          email: vData.ShiaEmail,
        };

        await axios
          .post(
            "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Discover-Benefits-Encryption", 
            requestObj,
          )
          .then((result) => {
            vData.encryptedLink = result.data;

            vData.$router.push({
              name: "Third",
              params: {
                adultsPath: vData.AdultsInHousehold,
                childPath: vData.ChildrenInHousehold,
                yearsPath: vData.YearsOfResidence,
                disabilityPath: vData.DisabilityOption,
                primaryResidencePath: vData.PrimaryResidence,
                homeownerPath: vData.HomeownerOption,
                incomePath: vData.HouseholdIncome,
                spropertyPath: vData.SecondProperty,
                deedPath: vData.NameOnDeed,
                referralPath: vData.referralType,
                employeePath: vData.EmployeeStatus,
                roofLeakPath: vData.RoofLeak,
                electricProblemPath: vData.ElectricProblem,
                plumbingProblemPath: vData.PlumbingProblem,
                structuralProblemPath: vData.StructuralProblem,
                floorStairsPath: vData.FloorStairs,
                heaterOrHotWaterPath: vData.HeaterOrHotWater,
                helpInHomePath: vData.HelpInHome,
                tangleTitlePath: vData.TangleTitle,
                solarPowerPath: vData.SolarPower,
                billProblemPath: vData.BillProblem,
                //shiaEmailPath: vData.ShiaEmail,
                encryptedLinkPath: vData.encryptedLink,
                shiaEmail: vData.ShiaEmail,
              },
            });
          });    
      }
    },
  }, 
};
</script>

<style scoped>
/* Base styles */
.back-link {
  font-size: 16px;
  color: #0f4d90;
}
.background-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  margin-top: 80px;
}

.form-container {
  flex: 1;
  margin: 0 5%;
  padding: 20px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.left-img-container,
.right-img-container {
  display: none;
  position: fixed;
  top: 80px;
  height: 100vh;
  width: 20%;
  overflow: hidden;
}
.left-img-container {
  left: 0;
}
.right-img-container {
  right: 0;
}
.apps {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.apps a {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: underline;
  
}

input[type="radio"] + label {
  color: black;
  margin-left: 5px;
  font-weight: normal;
}



/* Mobile phones (portrait and landscape) */
@media only screen and (max-width: 767px) {
  .form-container {
    margin: 0;
    padding: 10px;
  }

  h1 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }
}

/* Tablets (portrait and landscape) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .form-container {
    margin: 0 10%;
  }

  .apps a {
    width: 50%;
  }

  h1 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
  }
}

/* Laptops and small desktops */
@media only screen and (min-width: 1024px) and (max-width: 1920px) {
  .left-img-container,
  .right-img-container {
    display: block;
    position: absolute;
    top: 0;
    height: 600px;
    width: 20%;
    overflow: hidden;
    z-index: -1;
  }

  .left-img-container {
    left: 0;
  }

  .right-img-container {
    right: 0;
  }
  .left-img,
.right-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: sticky;
  top: 0;
}

.left-img {
  object-position: center 1%;
}

  .form-container {
    margin: 0 20%;
  }

  .apps a {
    width: 33.33%;
  }
}

/* Large desktops and high-resolution displays */
@media only screen and (min-width: 1440px) {
  .left-img-container,
  .right-img-container {
    width: 25%;
  }

  .form-container {
    margin: 0 25%;
  }

  .apps a {
    width: 25%;
  }
}

/* Shared styles for images */
.left-img,
.right-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.left-img {
  object-position: center 1%;
  padding-right: 40px;
}

/*responsive adjustments */
@media only screen and (max-width: 1023px) {
  .background-container {
    background-color: #f0f0f0; /* Light background for smaller screens */
  }
  .left-img-container,
  .right-img-container {
    display: none;
  }

  .form-container {
    margin: 0 20px;
  }
}

/*form elements are responsive */
input[type="text"],
input[type="number"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

/* Base styles for form inputs */
input[type="text"],
input[type="number"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: white;
}

/* Media query for screens smaller than 1024x768 */
@media only screen and (max-width: 1023px) {
  .form-container {
    background-color: #f0f0f0; 
  }
  
  /* Target all input elements within the form */
  .form-container input[type="text"],
  .form-container input[type="number"],
  .form-container input[type="email"],
  .form-container select,
  .form-container textarea {
    background-color: white;
  }
}

/* Media query for 1024x768 and larger screens */
@media only screen and (min-width: 1024px) {
  .left-img-container,
  .right-img-container {
    display: block;
    width: 25%;
  }
  .form-container {
    margin: 0 22%; /* Adjust margins to account for side images */
  }
  .form-container input[type="text"],
  .form-container input[type="number"],
  .form-container input[type="email"],
  .form-container select,
  .form-container textarea {
    background-color: transparent;
  }
}

/* Styles for 1024px width and larger screens */
@media only screen and (min-width: 1024px) {
  .form-container input[type="text"],
  .form-container input[type="number"],
  .form-container input[type="email"],
  .form-container select,
  .form-container textarea {
    background-color: #f0f0f0; /* Light grey background for input fields on larger screens */
  }
}

/*radio buttons remain visible */
.radio-fieldset {
  border: none;
  padding: 12px;
  margin: 8px 0;
  outline: none;
  border-radius: 4px;
}

.radio-fieldset:focus-within {
  background-color: rgba(15, 77, 144, 0.05);
  outline: 2px solid #0f4d90;
  outline-offset: 2px;
}

/* Radio button styles */
input[type="radio"] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
}

/* Remove default focus styles */
input[type="radio"]:focus {
  outline: none;
}

/* Focus and hover states */
.radio-option:focus-within,
.radio-option:hover {
  background-color: rgba(15, 77, 144, 0.05);
}


/* Selected state */
input[type="radio"]:checked + label {
  color: #0f4d90;
  font-weight: normal;
}

/* Ensure form elements are properly spaced */
.cell {
  margin-bottom: 16px;
}

/* Ensure proper focus visibility */
.radio-fieldset:focus {
  outline: 2px solid #0f4d90;
  outline-offset: 2px;
}
/* Extra large screens */
@media only screen and (min-width: 1920px) {
  .left-img-container,
  .right-img-container {
    width: 25%; /* Wider images on very large screens */
    height: 800px;
  }

  .form-container {
    margin: 0 27%; /* Adjust form margins accordingly */
  }
}

/* Ultra-wide screens */
@media only screen and (min-width: 2560px) {
  .left-img-container,
  .right-img-container {
    width: 30%;
    height: 1000px;
  }

  .form-container {
    margin: 0 32%;
  }
}
</style>
