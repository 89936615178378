export default {
  header: 'i18nBanner',
  enabled: true,
  English: {
    message: {
      homeTitle: 'One Philly Front Door',
      homeQuestionLabel: 'How to Use One Philly Front Door Housing Portal',//'Use our housing helper to find what programs you might be eligible for', //resend
      homeDescriptionText: 'Please fill out the fields below. Based on the information provided, this tool will inform you whether you may be qualified for the following City of Philadelphia and PHDC housing programs and initiatives:',//'The Department of Planning and Development (DPD) supports many programs and services that help residents buy, repair, and keep their home. By answering a few questions below, you will get a list of programs for which you may be eligible. For most of the programs you will have the option for someone to contact you. There are a few programs that you will have to contact them directly.',
      homeDescriptionText2: 'Please note: Currently One Philly Front Door is a search tool, not an application. You will receive the name, number and website for each program on your list. Many participating programs will contact you directly to help you prepare your applications. A few other programs will require you to reach out.',//'All results will provide you with a name, number and/or website to begin the process.',
      comingSoon: 'Coming Soon: We are expanding to allow users to apply to programs directly through One Philly Front Door. At that time you will be able to apply directly through the portal and to submit your application and all supporting documents one time only.',
      homeAdultsQuestion: 'How many adults 19 and older live in your household?',
      homeAdultsError: 'Please enter a number for the number of adults in your household.',
      homeChildrenQuestion: 'How many children 18 and younger live in your household?',
      homeChildrenError: 'Please enter a number for the number of children in your household.',
      homeHouseholdIncome: 'What is your estimated gross annual household income?',
      homeHouseholdIncomeError: 'Please enter your household income.',
      homeYearsOfResidence: 'How many years have you resided in your home?',
      homeYearsOfResidenceError: 'Please enter the amount of years you have lived at your current residence.',
      primaryResidence: 'Is your Primary Residence in Philadelphia?',
      primaryResidenceError: 'Please indicate below whether your current residency is in Philadelphia.',
      homeDeed: 'Is your name on the deed of the house or the lease?',
      homeDeedEror: 'Please indicate whether your name is on the lease or the deed.',
      homeHomeownerLabel: 'Are you a renter or a homeowner?',
      homeHomeownerError: 'Please indicate your home ownership status.',
      homeAdditionalProperties: 'Do you own additional properties in Philadelphia?',
      homeAdditionalPropertiesError: 'Please indicate if you own additional properties.',
      homeDisability: 'Do you or a member of your household have a physical disability?',
      homeDisabilityError: 'Please indicate a disability status.',
      homeReferralQuestion: 'How did you hear about us?',
      homeReferralQuestionError: 'Please select how you heard about this tool.',
      employeeQuestion: 'Are you an employee of the City of Philadelphia?' ,
      employeeQuestionError: 'Please indicate below whether you are an employee of the City of Philadelphia.',
      contactLabel: 'Contact',
      yesLabel: 'Yes',
      noLabel: 'No',
      deedLabel: 'Deed',
      leaseLabel: 'Lease',
      neitherLabel: 'Neither',
      homeownerLabel: 'Homeowner',
      renterLabel: 'Renter',
      wordOfMouthLabel: 'Word of Mouth (friend, family, neighbor)',
      socialMediaLabel: 'Social Media',
      communityPartnerLabel: 'Community Partner (flier, event, program, newsletter, email)',
      cityCouncilLabel: 'City Council Office',
      newspaperLabel: 'Newspaper or blog article',
      emailOptionLabel: 'Email from PHDC or the City of Philadelphia',
      otherLabel: 'Other',
      homeSubmissionLabel: 'All questions are required. Please ensure all questions have been answered before submitting.',
      homeSubmit: 'Submit',
      homeThankYou: 'Thanks for your submission!',
      homeSubmittingLabel: 'Submitting...',
      homeApplicationError: 'There are errors in your application, please review the form and make sure all information is entered correctly.',
      resultsTitle: 'One Philly Front Door Housing Portal',
      resultsResultsLabel: 'Results:',
      resultsTripRContactLabel: 'Please select who you would like to contact you:',
      resultsNoQualifications: 'Sorry, you do not qualify for any programs. Please check back at another time.',
      websiteLabel: 'Go to Website',
      secondWebsiteLabel: 'Apply to this program',
      resultsDescription: 'These are the program(s) you wish to be contacted about. Please review and confirm your consent by checking the consent then proceed to fill out your detailed information.',
      secondResultsDescription: 'If you would like a copy of these results and someone to contact you, please select "I want to be contacted", fill in your contact information and click SUBMIT. You will be contacted to discuss the program(s). For Housing Counseling, Tangled Title, Neighborhood Energy Centers, and Weatherization Assistance Program services, please contact the organization directly.',
      resultsSelectProgram: 'Please select at least one program before submitting.',
      resultsReturnToHomepage: 'Return to the Homepage.',
      resultsFullName: 'Enter your full name:',
      resultsFullNameError: 'Please enter your full name.',
      resultsEmail: 'Enter your email address:',
      resultsEmailError: 'Please enter your email address.',
      resultsAddress: 'Enter your Address line 1:',
      resultsAddressError: 'Please enter your home address.',
      resultsAddress2: 'Enter your Address line 2:',
      resultsCity: 'Enter your City:',
      resultsCityError: 'Please enter the city where you currently reside.',
      resultsState: 'Enter your State:',
      resultsStateError: 'Please enter the state where you currently reside.',
      resultsZip: 'Enter your Zip Code:',
      resultsZipError: 'Please enter your zip code.',
      resultsPhone: 'Enter your Phone Number:',
      resultsPhoneError: 'Please enter your phone number.',
      resultsSubmissionThanks: 'Thanks for your submission!',
      resultsSubmitting: 'Submitting, please wait',
      resultsSubmissionError: 'Something went wrong, please try submitting again.',
      resultsSubmitInfoButton: 'Submit Information',
      resultsContactButton: 'I want to be contacted',
      roofLeak: 'Do you need help fixing a major roof leak?',
      roofLeakError: 'Please indicate below whether you are in need of a roof leak repair',
      electricProblem: 'Do you need help fixing a major electrical problem?',
      electricProblemError: 'Please indicate below whether you are in need of a electrical repair',
      plumbingProblem: 'Do you need help fixing a major plumbing problem?',
      plumbingProblemError: 'Please indicate below whether you are in need of a plumbing repair',
      structuralProblem: 'Do you need help fixing a structural problem? Roof, exterior walls, cracks or bulging on walls and ceiling.',
      structuralProblemError: 'Please indicate below whether you are in need of a structural repair',
      floorStairs: 'Do you need help with fixing your floors or interior stairs?',
      floorStairsError: 'Please indicate below whether you are in need of floors & stairs repair',
      heaterOrHotWater: 'Do you need help with fixing your heater or hot water system?',
      heaterOrHotWaterError: 'Please indicate below whether you are in need of a heater or a hot water repair',
      helpInHome: 'Do you need help getting in and out your home or moving around inside your home due to a physical disability?',
      helpInHomeError: 'Please indicate below whether you are in need of a assistance in your home.',
      tangleTitle: 'Do you need help with a Tangled title?',
      tangleTitleError: 'Please indicate below whether you are in need of a assistance with a Tangled title.',
      solarPower: 'Are you interested in adding rooftop solar power to your home to lower your electric bill?',
      solarPowerError: 'Please indicate below whether you are in need of a rooftop solar panel',
      billProblem: 'Do you have problems with paying a electricity bill or any utility bills?',
      billProblemError: 'Please indicate below whether you are in need of assistance with your bills.',
      amodDescription: 'Assists residents with physical disabilities to adapt their homes or apartments to increase access and mobility.',
      bsrpDescription: 'Free emergency repairs to electrical, plumbing, heating, roofing and other systems.',
      heaterDescription: 'Free emergency repairs to furnaces/boilers.',
      turnTheKeyDescription: 'Will help up to 1,000 Philadelphia families buy new affordable homes and build equity for their futures.',
      pfhDescription: 'Provides grants up to $10,000 for first time home buyers.',
      pfhNote: 'Note: This program is currently on hold.',
      housingCounselingDescription: '   Multiple locations, helps residents to prepare for home ownership, manage property issues or resolve foreclosures.',
      tangledTitleDescription: 'Use Save Your Home Philly Hotline to refer any callers with questions about untangling title and who want referrals to the main agencies who help with TTF applications.',
      tangledTitleDescription2: 'Grants to assist low-income residents gain clear legal title to their home.',
      tripleRDescription: 'Low-interest loans for home repair.',
      tripleRContactInfo: 'Call Clarifi to begin the intake process.',
      utilityGrantDescription: 'Helps customers to bring their delinquent utilitiy accounts up to date.',
      homeAlertPopup: 'All questions are required. Please ensure all questions have been answered before sumbitting.',
      resultsAlert1: 'Please enter all required information and try again.',
      resultsAlert2: 'Your request has been submitted. You will receive a confirmation email shortly.',
      solarPowerDescription: 'Solarize Philly is a citywide program to help all Philadelphians go solar.',
      builtToLastDescription: 'Built to Last delivers critical home repairs, health and safety improvements, and energy efficiency and clean energy improvements. Contact email: builttolast@philaenergy.org',
      builtToLastEmail: 'Contact email:builttolast@philaenergy.org',
      necDescription: 'Neighborhood Energy Centers provide information on how to save on utilities and get help paying energy bills.',
      secondaryQuestion: 'Secondary Questions',
      secondaryQuestionError: 'All Secondary questions are required. Please ensure all questions have been answered before sumbitting.',
      shiaEmail: 'Please provide your email address.',
      shiaEmailError: 'You must provide your email to continue.',
      wapDescription: 'Pennsylvania’s Weatherization Assistance Program increases energy efficiency in homes by reducing energy costs and increasing comfort while safeguarding health and safety.',
      loopDescription: 'The Longtime Owner Occupants Program (LOOP) in Philadelphia provides Real Estate Tax relief to eligible homeowners whose property assessments have increased significantly (at least 50% over last year or 75% over the past five years). The program limits the assessment increase and locks it in for as long as the homeowner remains eligible, based on income and length of homeownership requirements.',
      homesteadDescription: 'The Homestead Exemption is a program in Philadelphia that reduces the taxable portion of a homeowner\'s property assessment by $80,000, resulting in typical savings of up to $1,119 each year starting in 2023. To qualify, the homeowner must own the property and use it as their primary residence, and once the application is accepted, they never have to reapply unless their deed changes.',
      // Landing Page
      welcomeMessage: 'Welcome to One Philly Front Door! This is your one-stop shop for finding housing-related programs and services in the City of Philadelphia.',
      instructions: 'Click the Get Started button and answer a few easy questions, and One Philly Front Door will show you a personalized list of programs that might be available to assist you.',
    },
  },
  Spanish: {
    message: {
      homeTitle: 'Portal de vivienda de la puerta principal de One Philly',
      homeQuestionLabel: 'Cómo utilizar el portal de vivienda One Philly Front Door',//'Utilice nuestro ayudante de vivienda para determinar para cuales programas usted podría ser elegible',
      homeDescriptionText: 'Por favor complete los campos a continuación. Según la información proporcionada, esta herramienta le informará si puede calificar para los siguientes programas e iniciativas de vivienda de la Ciudad de Filadelfia y del PHDC:',//'El Departamento de Planificación y Desarrollo (DPD por sus siglas en inglés), financia muchos programas y servicios que ayudan a residentes a comprar, reparar y retener sus viviendas. Al contestar unas pocas preguntas adelante, usted obtendrá una lista de programas para los cuales podría ser elegible. Para la mayoría de los programas usted tendrá la opción de que alguien se ponga en contacto con usted. Hay algunos programas con los que usted tendrá que comunicarse directamente.',
      homeDescriptionText2: 'Tenga en cuenta: actualmente One Philly Front Door es una herramienta de búsqueda, no una aplicación. Recibirá el nombre, el número y el sitio web de cada programa de su lista. Muchos programas participantes se comunicarán con usted directamente para ayudarlo a preparar sus solicitudes. Algunos otros programas requerirán que usted se comunique.',//'Todos los resultados le proporcionarán un nombre, número y/o sitio web para comenzar el proceso. Tenga en cuenta: Esto NO es una aplicación. Esta es una herramienta de búsqueda.',
      comingSoon: 'Próximamente: nos estamos expandiendo para permitir que los usuarios presenten solicitudes para programas directamente a través de One Philly Front Door. En ese momento podrá presentar su solicitud directamente a través del portal y enviar su solicitud y todos los documentos de respaldo una sola vez.',
      homeAdultsQuestion: '¿Cuántos adultos de 19 años o más viven en su casa?',
      homeAdultsError: 'Favor de rellenar un número  de la cantidad de adultos viviendo en su casa.',
      homeChildrenQuestion: '¿Cuántos niños de 18 años o menos viven en su casa?',
      homeChildrenError: 'Favor de rellenar un número de la cantidad de niños viviendo en su casa.',
      homeHouseholdIncome: '¿Cuál es el ingreso anual bruto estimado de su hogar?',
      homeHouseholdIncomeError: 'Favor de rellenar el ingreso anual bruto de su hogar.',
      homeYearsOfResidence: '¿Cuántos años ha vivido usted en su casa?',
      homeYearsOfResidenceError: 'Favor de rellenar el número de años que usted ha vivido en su residencia actual.',
      primaryResidence: '¿Su residencia primaria es en Filadelfia?',
      primaryResidenceError: 'Favor de indicar debajo si su residencia actual es en Filadelfia.',
      homeDeed: '¿Aparece su nombre en la escritura o contrato de arrendamiento de la casa?',
      homeDeedError: 'Favor de indicar si su nombre está en la escritura o contrato de arrendamiento de la casa.',
      homeHomeownerLabel: '¿Es usted un arrendatario o un propietario?',
      homeHomeownerError: 'Favor de indicar su condición respecto a la propiedad donde reside.',
      homeAdditionalProperties: '¿Es usted dueño de propiedades adicionales en Filadelfia?',
      homeAdditionalPropertiesError: 'Favor de indicar si usted es dueño de propiedades adicionales.',
      homeDisability: '¿Usted o algún miembro de su hogar tiene una discapacidad física?',
      homeDisabilityError: 'Favor de indicar si tiene una discapacidad.',
      homeReferralQuestion: '¿Cómo se enteró de nosotros?',
      homeReferralQuestionError: 'Favor de seleccionar como se enteró sobre esta herramienta.',
      employeeQuestion: '¿Es usted un empleado de la ciudad de Filadelfia?',
      employeeQuestionError: 'Indique a continuación si es un empleado de la ciudad de Filadelfia.',
      contactLabel: 'Contacto',
      yesLabel: 'Si',
      noLabel: 'No',
      deedLabel: 'Escritura',
      leaseLabel: 'Contrato de arrendamiento',
      neitherLabel: 'Ninguno',
      homeownerLabel: 'Dueño de casa',
      renterLabel: 'Arrendatario',
      wordOfMouthLabel: 'Boca a boca (amigo, familiar, vecino)',
      socialMediaLabel: 'Redes sociales',
      communityPartnerLabel: 'Socio comunitario (volante, evento, programa, boletín informativo, correo electrónico)',
      cityCouncilLabel: 'Oficina del Consejo Municipal',
      newspaperLabel: 'Periódico o artículo en un blog',
      emailOptionLabel: 'Correo electrónico de PHDC o la ciudad de Filadelfia',
      otherLabel: 'Otro',
      homeSubmissionLabel: 'Debe responder a todas las preguntas. Favor de asegurarse de que contestó todas las preguntas antes de enviar.',
      homeSubmit: 'Envíe el cuestionario',
      homeThankYou: '¡Gracias por someter su cuestionario!',
      homeSubmittingLabel: 'Enviando…',
      homeApplicationError: 'Hay errores en su solicitud. Favor de revisar el formulario y asegurarse de que ha rellenado toda la información correctamente.',
      resultsTitle: 'Portal de vivienda de la puerta principal de One Philly',
      resultsResultsLabel: 'Resultados:',
      resultsTripRContactLabel: 'Favor de seleccionar quien quiere que se ponga en contacto con usted:',
      resultsNoQualifications: 'Lo sentimos, usted no cualifica para ninguno de los programas. Por favor intente de nuevo en otra ocasión.',
      websiteLabel: 'Ir al sitio web',
      secondWebsiteLabel: 'Aplicar a este programa',
      resultsDescription: 'Estos son los programas sobre los que desea que le contactemos. Revise y confirme su consentimiento marcando el consentimiento y luego proceda a completar su información detallada.',
      secondResultsDescription: 'Si desea una copia de estos resultados y que alguien se comunique con usted, seleccione "Quiero que me contacten", complete su información de contacto y haga clic en ENVIAR. Nos comunicaremos con usted para discutir el programa(s). Para obtener servicios de asesoramiento sobre vivienda, títulos enredados, centros de energía vecinales y programas de asistencia para la climatización, comuníquese directamente con la organización.',
      resultsReturnToHomepage: 'Volver a la página inicial.',
      resultsSelectProgram: 'Favor de seleccionar al menos un programa antes de enviar.',
      resultsFullName: 'Rellene su nombre completo:',
      resultsFullNameError: 'Favor de rellenar su nombre completo.',
      resultsEmail: 'Rellene su correo electrónico:',
      resultsEmailError: 'Favor de rellenar su correo electrónico.',
      resultsAddress: 'Rellene su dirección línea 1:',
      resultsAddressError: 'Favor de rellenar su dirección residencial.',
      resultsAddress2: 'Rellene su dirección línea 2:',
      resultsCity: 'Rellene su ciudad:',
      resultsCityError: 'Favor de rellenar la ciudad donde usted reside actualmente.',
      resultsState: 'Rellene su estado:',
      resultsStateError: 'Favor de rellenar el estado donde usted reside actualmente.',
      resultsZip: 'Rellene su zona postal:',
      resultsZipError: 'Favor de rellenar su zona postal.',
      resultsPhone: 'Rellene su número de teléfono:',
      resultsPhoneError: 'Favor de rellenar su número de teléfono.',
      resultsSubmissionThanks: '¡Gracias por someter su cuestionario!',
      resultsSubmitting: 'Enviando, espere por favor',
      resultsSubmissionError: 'Hubo un error, por favor trate de enviar otra vez.',
      resultsSubmitInfoButton: 'Envíe información',
      resultsContactButton: 'quiero ser contactado',
      roofLeak: '¿Necesita ayuda para reparar una gotera importante en el techo?',
      electricProblem: '¿Necesita ayuda para solucionar un problema eléctrico importante?',
      plumbingProblem: '¿Necesita ayuda para solucionar un problema importante de plomería?',
      structuralProblem: '¿Necesita ayuda para solucionar un problema estructural? Techo, paredes exteriores, grietas o abultamientos en paredes y techo.',
      floorStairs: '¿Necesitas ayuda para arreglar tus suelos o escaleras interiores?',
      heaterOrHotWater: '¿Necesitas ayuda para arreglar tu calentador o sistema de agua caliente?',
      helpInHome: '¿Necesita ayuda para entrar y salir de su casa o moverse dentro de su casa debido a una discapacidad física?',
      tangleTitle: '¿Necesitas ayuda con un título de Enredados?',
      tangleTitleError: 'Indique a continuación si necesita ayuda con un título de Tangled.',
      solarPower: '¿Está interesado en agregar energía solar en la azotea de su hogar para reducir su factura de electricidad?',
      billProblem: '¿Tienes problemas para pagar la factura de la luz? ¿Alguna factura de servicios públicos?',
      amodDescription: 'Ayuda a residentes con discapacidades físicas a modificar sus casas o apartamentos para mejorar la accesibilidad y la movilidad.',
      bsrpDescription: 'Reparaciones de emergencia gratis de sistemas eléctricos, de plomería, calefacción, techos y otros.',
      heaterDescription: 'Reparaciones de emergencia gratis para calderas.',
      turnTheKeyDescription: 'Ayudará a hasta 1,000 familias de Filadelfia a comprar nuevas viviendas asequibles y generar capital para su futuro.',
      pfhDescription: 'Otorga subvenciones de hasta $10,000 para comprar su primera casa.',
      pfhNote: 'Aviso: Este programa está suspendido actualmente.',
      housingCounselingDescription: 'Múltiples ubicaciones. Ayuda a residentes a prepararse para comprar una casa, manejar asuntos de la propiedad o resolver ejecuciones hipotecarias.',
      tangledTitleDescription: 'Use la Línea Directa “Save Your Home Philly” para referir cualquier persona que telefonee con preguntas sobre desenredar títulos de propiedad y que desean referidos a las principales agencias que prestan ayuda con solicitudes TTF.',
      tangledTitleDescription2: 'Subvenciones para ayudar a residentes de bajos ingresos a obtener títulos de propiedad legales y libres de gravámenes sobre sus casas.',
      tripleRDescription: 'Préstamos a bajo interés para reparaciones en el hogar.',
      tripleRContactInfo: 'Llame a Clarifi para comenzar el proceso de admisión.',
      utilityGrantDescription: 'Ayuda a clientes a poner sus cuentas morosas de servicios públicos al día.',
      homeAlertPopup: 'Debe responder a todas las preguntas. Favor de asegurarse de que contestó todas las preguntas antes de enviar.',
      resultsAlert1: 'Por favor rellene toda la información requerida y trate otra vez.',
      resultsAlert2: 'Su solicitud ha sido enviada. Recibirá una confirmación por correo electrónico en breve.',
      solarPowerDescription: 'Solarize Philly es un programa de toda la ciudad para ayudar a todos los habitantes de Filadelfia a usar energía solar.',
      builtToLastDescription: 'Construido para durar ofrece reparaciones críticas del hogar, mejoras de salud y seguridad, y mejoras de eficiencia energética y energía limpia.',
      necDescription: 'Los Centros Vecinales de Energía brindan información sobre cómo ahorrar en servicios públicos y obtener ayuda para pagar las facturas de energía.',
      secondaryQuestion: 'Preguntas secundarias',
      secondaryQuestionError: 'Todas las preguntas secundarias son obligatorias. Asegúrese de haber respondido todas las preguntas antes de enviarlas.',
      shiaEmail: 'Por favor proporcione su dirección de correo electrónico.',
      shiaEmailError: 'Debes proporcionar tu correo electrónico para continuar.',
      wapDescription: 'El Programa de Asistencia de Climatización de Pensilvania aumenta la eficiencia energética en los hogares al reducir los costos de energía y aumentar la comodidad al tiempo que protege la salud y la seguridad.',
      loopDescription: 'El Programa de Ocupantes Propietarios de Larga Duración (LOOP, por sus siglas en inglés) en Filadelfia proporciona alivio del impuesto sobre bienes raíces a los propietarios elegibles cuyas evaluaciones de propiedad han aumentado significativamente (al menos un 50% durante el año pasado o un 75% en los últimos cinco años). El programa limita el aumento de la tasación y lo bloquea durante el tiempo que el propietario siga siendo elegible, según los ingresos y la duración de los requisitos de propiedad de vivienda.',
      homesteadDescription: 'La Exención de Homestead es un programa en Filadelfia que reduce la parte imponible de la evaluación de la propiedad de un propietario en $80,000, lo que resulta en ahorros típicos de hasta $1,119 cada año a partir de 2023. Para calificar, el propietario debe ser dueño de la propiedad y usarla como su residencia principal, y una vez que se acepta la solicitud, nunca tiene que volver a presentar una solicitud a menos que cambie su escritura.',
      // Landing Page
      welcomeMessage: '¡Bienvenido a una puerta principal de Filadelfia! Esta es su ventanilla única para encontrar programas y servicios relacionados con la vivienda en la ciudad de Filadelfia.',
      instructions: 'Haga clic en el botón Comenzar y responda algunas preguntas sencillas, y One Philly Front Door le mostrará una lista personalizada de programas que podrían estar disponibles para ayudarlo.',
    },
  },
};
