<template>
  <div 
    class="landing-container" 
    role="main" 
    aria-label="One Philly Front Door Landing Page"
  >
    <!-- Header img -->
    <div 
      class="image-section" 
      role="banner"
    >
      <img 
        src="../../public/OPFDH.jpg" 
        alt="Welcome to One Philly Front Door Header Image"
        aria-label="Welcome banner for One Philly Front Door website"
      >
    </div>
    <div 
      class="content-button-wrapper" 
      role="article"
    >
      <div 
        class="text-section"
      >
        <p 
          class="welcome-text" 
          aria-label="Welcome message"
        >
          {{ $t("message.welcomeMessage") }}
        </p>
        <p 
          class="instruction-text" 
          aria-label="Instructions"
        >
          {{ $t("message.instructions") }}
        </p>
      </div>
    </div>
    <div 
      class="button-container"
    >
      <button 
        class="image-button" 
        aria-label="Get Started - Begin your housing assistance journey" 
        tabindex="0" 
        @click="goToHome"
      >
        <img 
          src="../../public/OPFDB.png" 
          alt="Get Started Button"  
          role="img"
        >
      </button>
    </div>

    <!-- Logo Section -->
    <div 
      class="logos-container" 
      role="complementary" 
      aria-label="Partner Organizations"
    >
      <div 
        class="logos-grid"
      >
        <a 
          href="https://phdcphila.org" 
          target="_blank" 
          class="logo-link"
          aria-label="Visit Philadelphia Housing Development Corporation website in new tab"
        >
          <img 
            src="../../public/PHDC202412.png" 
            alt="Philadelphia Housing Development Corporation Logo"
            role="img"
          >
        </a>
        <a 
          href="https://phlcouncil.com" 
          target="_blank" 
          class="logo-link"
          aria-label="Visit Philadelphia City Council website in new tab"
        >
          <img 
            src="../../public/CityCouncil.jpg" 
            alt="Philadelphia City Council Logo"
            role="img"
          >
        </a>
        <a 
          href="https://philaenergy.org/" 
          target="_blank" 
          class="logo-link"
          aria-label="Visit Philadelphia Energy Authority website in new tab"
        >
          <img 
            src="../../public/PEA_logo.png" 
            alt="Philadelphia Energy Authority Logo"
            role="img"
          >
        </a>
        <!-- <a 
          href="https://www.phila.gov/departments/department-of-planning-and-development" 
          target="_blank" 
          class="logo-link"
          aria-label="Visit Department of Planning and Development website in new tab"
        >
          <img 
            src="../../public/DPD_belllogo.png" 
            alt="Department of Planning and Development Logo"
            role="img"
          >
        </a> -->
        <a 
          href="https://www.phila.gov/departments/division-of-housing-and-community-development" 
          target="_blank" 
          class="logo-link"
          aria-label="Visit Division of Housing and Community Development website in new tab"
        >
          <img 
            src="../../public/NewHousingImg.jpg" 
            alt="Division of Housing and Community Development Logo"
            role="img"
          >
        </a>
        <a 
          href="https://www.pcacares.org" 
          target="_blank" 
          class="logo-link"
          aria-label="Visit Philadelphia Corporation for Aging website in new tab"
        >
          <img 
            src="../../public/PCA.png" 
            alt="Philadelphia Corporation for Aging Logo"
            role="img"
          >
        </a>
        <a 
          href="https://ecasavesenergy.org" 
          target="_blank" 
          class="logo-link"
          aria-label="Visit Energy Coordinating Agency website in new tab"
        >
          <img 
            src="../../public/ECA.jpg" 
            alt="Energy Coordinating Agency Logo"
            role="img"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Landing',
  methods: {
    goToHome() {
      this.$router.push('/Home');
    },
  },
};
</script>
  
  <style scoped>
  /* Button and Link Focus States */

  .housing-logo {
  width: 200px;
  height: 100px;
  object-fit: contain;
  padding: 10px;
}
.image-button:focus {
  outline: 3px solid #0f4d90; /* City of Philadelphia blue */
  outline-offset: 2px;
  border-radius: 4px;
}

.image-button:focus:not(:focus-visible) {
  outline: none;
}

.image-button:focus-visible {
  outline: 3px solid #0f4d90;
  outline-offset: 2px;
  border-radius: 4px;
}

/* Translation Dropdown Focus States */
.select-language:focus,
.select-language:focus-visible {
  outline: 3px solid #0f4d90;
  outline-offset: 2px;
  border-radius: 4px;
  box-shadow: 0 0 0 3px rgba(15, 77, 144, 0.2);
}

.select-language:focus:not(:focus-visible) {
  outline: none;
}

/* Ensure dropdown remains visible when focused */
.select-language:focus {
  z-index: 100;
  position: relative;
}

/* High contrast mode support */
@media (forced-colors: active) {
  .image-button:focus,
  .select-language:focus {
    outline: 3px solid CanvasText;
  }
}
.content-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
  .landing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  overflow-x: hidden;
}

.image-section {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.image-section img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  max-width: 100%;
}

.text-section {
  padding: 1rem;
  max-width: 800px;
  width: 80%;
  flex-wrap: wrap;
  margin-top: 20px;
}

.button-container {
  max-width: 800px;
  width: 80%;
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}

.image-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: -1rem;
}

.image-button img {
  max-width: 100%;
  height: auto;
  display: block;
}
.logos-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  margin-top: 2rem;
}

.logos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  max-width: 1200px;
  width: 90%;
  justify-items: center;
  align-items: center;
}

.logo-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 80px;
  transition: transform 0.2s;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
}

.logo-link:hover {
  transform: scale(1.05);
}

.logo-link img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  object-position: center;
}

.logo-link img[alt="Division of Housing and Community Development Logo"] {
  min-width: 300px;
  min-height: 125%;
  object-fit: contain;
  transform: none;
}

/* .logo-link img[alt="Planning and Developement"] {
  min-width: 350px;
  object-fit: contain;
  padding-left: 19%;
} */

.logo-link img[alt="Philadelphia Energy Authority Logo"] {
  min-width: 350px;
  min-height: 175%;
  object-fit: contain;
  padding-left: 19%;
}

/* Ensure consistent logo sizes for mobile */
@media screen and (max-width: 768px) {
  .logo-link img[alt="Division of Housing and Community Development Logo"] {
    width: 200px;
    height: 60px;
  }
  /* .logo-link img[alt="Planning and Developement"] {
    min-width: 200px;
    padding-left: 0;
  } */

  .logo-link img[alt="Philadelphia Energy Authority Logo"] {
    min-width: 300px;
    padding-left: 0;
  }
}

.logo-link:focus {
  outline: 2px solid #0f4d90; /* City of Philadelphia blue */
  outline-offset: 2px;
}

.logo-link:focus:not(:focus-visible) {
  outline: none;
}

.logo-link:focus-visible {
  outline: 2px solid #0f4d90;
  outline-offset: 2px;
}
/* Responsive adjustments */
@media screen and (max-width: 1024px) {
  .logos-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .logo-link img[alt="Division of Housing and Community Development Logo"] {
    width: 200px;  /* Mobile size */
  }
  .housing-logo {
    width: 120px;
    height: 60px;
  }
  .text-section,
  .button-container {
    width: 90%;
  }
  .landing-container {
    margin-top: 2.5px;
    margin-top: 60px;
  }
  
  .content-container {
    width: 90%;
  }
  .logos-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .logo-link img {
    max-width: 120px;
  }
}

@media screen and (max-width: 480px) {
  .logos-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
  </style>