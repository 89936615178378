<template>
  <form
    id="applicationSubmission"
    @submit.prevent="handleSubmit"
  >
    <div class="grid-container">
      <div class="grid-y grid-margin-x">
        <h2><b>{{ $t("message.resultsTitle") }}</b></h2>
        <h3><b>{{ $t("message.resultsResultsLabel") }}</b></h3>
        <div>{{ $t("message.resultsDescription") }}</div>
        <br>
        <div v-if="selectedPrograms.length > 0">
          <h2><b>Selected Programs</b></h2>
          <ul>
            <li
              v-for="(program, index) in selectedPrograms"
              :key="index"
            >
              <input
                v-model="program.applicantInterested"
                type="checkbox"
              >
              <strong>Name:</strong> {{ program.name }}<br>
              <strong>Phone Number:</strong> {{ program.phoneNumber }}<br>
              <strong>Description:</strong> {{ $t(program.description) }}<br>
              <strong>Website:</strong> <a
                :href="program.website"
                target="_blank"
              >{{ $t("message.websiteLabel") }}</a>
              <br><br>
            </li>
          </ul>
        </div>

        <div>
          <input
            id="hasReadRelease"
            v-model="hasReadRelease"
            type="checkbox"
          >
          <label for="hasReadRelease">
            I affirm that I have read this release or it has been read to me, and I understand its content.&nbsp; &nbsp;<br>
            <div class="dropdown">
              <button
                class="dropbtn"
                @click.prevent="toggleConsentForm"
              >Consent</button>
              <div
                id="consentFormContainer"
                style="display:none;"
              >
                <iframe
                  id="consentFormFrame"
                  width="500%"
                  height="1000px"
                  style="border:none;"
                />
              </div>
            </div>
          </label>
        </div>
        

        <!-- <div
          v-for="(program, idx) in programSuggestions"
          :key="idx"
        >
         <div>
          -- {{ program.name }}: {{ $t(program.description) }}
          &#8226; {{ program.phoneNumber }}
          &#8226; -->
        <!-- <component :is="program.website ? 'a' : 'span'" :href="program.website || ''" target="_blank">
            {{ $t("message.websiteLabel") }}
          </component> 
        </div> 
          <div
            v-if="
              checkEligibility(
                program.name,
                primaryResidencePath,
                childPath,
                adultsPath,
                incomePath,
                disabilityPath,
                homeownerPath,
                spropertyPath,
                deedPath,
                yearsPath,
                employeePath,//City of Philly Employee
                roofLeakPath,
                electricProblemPath,
                plumbingProblemPath,
                structuralProblemPath,
                floorStairsPath,
                heaterOrHotWaterPath,
                helpInHomePath,
                tangleTitlePath,
                solarPowerPath,
                billProblemPath,
              ) == true
            "
          > 
            {{program.name}}
            <input
              :id="program.name"
              v-model="program.applicantInterested"
              type="checkbox"
            >
            <label :for="program.name">{{ program.name }}:</label>
            <br><p style="margin-left: 0.8%">
              {{ $t(program.description) }} &#8226;
              {{ program.phoneNumber }} &#8226;
              <component
                :is="program.website ? 'a' : 'span'"
                :href="program.website || ''"
                target="_blank"
              >
                {{ $t("message.websiteLabel") }}
              </component>
              <br>
            </p>
            <div
              v-if="
                program.name == 'Restore Repair Renew Loan Program' &&
                  program.applicantInterested == true
              "
            >
              <div class="cell">
                <div
                  v-if="$v.TripleRSelection.$invalid"
                  class="love-park-red"
                >
                  {{ $t("message.resultsTripRContactLabel") }}
                </div>
                <div>
                  <input
                    id="input-triple-R-1"
                    v-model.trim="TripleRSelection"
                    type="radio"
                    name="input-triple-R"
                    value="Clarifi"
                  >
                  <label for="input-triple-R-1">Contact Clarifi</label>
                </div>
                <div>
                  <input
                    id="input-triple-R-2"
                    v-model.trim="TripleRSelection"
                    type="radio"
                    name="input-triple-R"
                    value="PCCA"
                  >
                  <label for="input-triple-R-2">Contact PCCA</label>
                </div>
              </div>
            </div>
            <br>
          </div>
        </div>-->
        <div
          v-if="resultsValid == true && $v.applicantInterestedList.$invalid"
          class="love-park-red"
        >
          {{ $t("message.resultsSelectProgram") }}
        </div>
        <!-- <div v-if="resultsValid == false">
          <b>{{ $t("message.resultsNoQualifications")
          }} <a href="./">{{ $t("message.resultsReturnToHomepage") }}</a></b>
        </div> -->
        <br>
        <div class="cell">
          <label for="Name">{{ $t("message.resultsFullName") }}</label>
          <div
            v-if="$v.Name.$invalid || $v.Name.$error"
            class="love-park-red"
          >
            {{ $t("message.resultsFullNameError") }}
          </div>
          <input
            id="input-name"
            v-model.trim="Name"
            name="input-name"
            type="Text"
          >
        </div>
        <div class="cell">
          <label for="EmailAddress">{{ $t("message.resultsEmail") }}</label>
          <div
            v-if="$v.EmailAddress.$invalid || $v.EmailAddress.$error"
            class="love-park-red"
          >
            {{ $t("message.resultsEmailError") }}
          </div>
          <input
            id="input-email-address"
            v-model.trim="EmailAddress"
            name="input-email-address"
            type="Text"
          >
        </div>
        <div class="cell">
          <label for="Address">{{ $t("message.resultsAddress") }}</label>
          <div
            v-if="$v.Address.Street1.$invalid || $v.Address.Street1.$error"
            class="love-park-red"
          >
            {{ $t("message.resultsAddressError") }}
          </div>
          <input
            id="input-address-street1"
            v-model.trim="Address.Street1"
            name="input-address-street1"
            type="Text"
          >
        </div>
        <div class="cell">
          <label for="Address">{{ $t("message.resultsAddress2") }}</label>
          <input
            id="input-address-street2"
            v-model.trim="Address.Street2"
            name="input-address-street2"
            type="Text"
          >
        </div>
        <div class="cell">
          <label for="Address.City">{{ $t("message.resultsCity") }}</label>
          <div
            v-if="$v.Address.City.$invalid || $v.Address.City.$error"
            class="love-park-red"
          >
            {{ $t("message.resultsCityError") }}.
          </div>
          <input
            id="input-address-city"
            v-model.trim="Address.City"
            name="input-address-city"
            type="Text"
          >
        </div>
        <div class="cell">
          <label for="Address.State">{{ $t("message.resultsState") }}</label>
          <div
            v-if="$v.Address.State.$invalid || $v.Address.State.$error"
            class="love-park-red"
          >
            {{ $t("message.resultsStateError") }}
          </div>
          <input
            id="input-address-state"
            v-model.trim="Address.State"
            name="input-address-state"
            type="Text"
          >
        </div>
        <div class="cell">
          <label for="Address.Zip">{{ $t("message.resultsZip") }}</label>
          <div
            v-if="$v.Address.Zip.$invalid || $v.Address.Zip.$error"
            class="love-park-red"
          >
            {{ $t("message.resultsZipError") }}
          </div>
          <input
            id="input-address-zip"
            v-model.trim="Address.Zip"
            name="input-address-zip"
            type="Text"
          >
        </div>
        <div class="cell">
          <label for="Phone">{{ $t("message.resultsPhone") }}</label>
          <div
            v-if="$v.Phone.$invalid || $v.Phone.$error"
            class="love-park-red"
          >
            {{ $t("message.resultsPhoneError") }}
          </div>
          <input
            id="input-phone"
            v-model.trim="Phone"
            name="input-phone"
            type="Text"
          >
        </div>
        <div class="cell">
          <h4
            v-if="submitStatus === 'OK'"
            class="typo__p"
          >
            {{ $t("message.resultsSubmissionThanks") }}
          </h4>
          <h4
            v-if="submitStatus === 'PENDING'"
            class="loader"
          >
            {{ $t("message.resultsSubmitting") }}
            <span class="loader__dot">.</span>
            <span class="loader__dot">.</span>
            <span class="loader__dot">.</span>
          </h4>
          <h4 v-if="submitStatus === 'POST_ERROR'">
            {{ $t("message.resultsSubmissionError") }}
          </h4>
        </div>
        <div class="grid-x grid-padding-x align-right">
          <div class="cell medium-4">
            <button
              :disabled="submitStatus === 'PENDING' || submitStatus === 'OK'"
              class="button"
            >
              {{ $t("message.resultsSubmitInfoButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {
  buildApplicationDataObject,
  dataObjectToXml,
} from "../utilities/functions";
import axios from "axios";
import { required, requiredIf } from 'vuelidate/lib/validators';
//import { map } from 'lodash';
export default {
  name: "Results",
  props: {
    selectedPrograms: { type: Array, default: null },
    adultsPath: { type: String, default: null },
    childPath: { type: String, default: null },
    incomePath: { type: String, default: null },
    yearsPath: { type: String, default: null },
    disabilityPath: { type: String, default: null },
    primaryResidencePath: { type: String, default: null },
    homeownerPath: { type: String, default: null },
    spropertyPath: { type: String, default: null },
    deedPath: { type: String, default: null },
    referralPath: { type: String, default: null },
    employeePath: { type: String, default: null },
    roofLeakPath: { type: String, default: null },
    electricProblemPath: { type: String, default: null },
    plumbingProblemPath: { type: String, default: null },
    structuralProblemPath: { type: String, default: null },
    floorStairsPath: { type: String, default: null },
    heaterOrHotWaterPath: { type: String, default: null },
    helpInHomePath: { type: String, default: null },
    tangleTitlePath: { type: String, default: null },
    solarPowerPath: { type: String, default: null },
    billProblemPath: { type: String, default: null },
  },
  data() {
    return {
      hasReadRelease: false,
      submitStatus: null,
      applicantInterestedList: null,
      Name: null,
      EmailAddress: null,
      Address: {
        Street1: null,
        Street2: null,
        City: null,
        State: null,
        Zip: null,
      },
      Phone: null,
      resultsValid: false,
      TripleRSelection: null,
      programs: [
        {
          id: 1,
          name: "Adaptive Modification Program", //complete
          applicantInterested: false,
          phoneNumber: "215-448-2160",
          description: "message.amodDescription",
          website:
            "https://phdcphila.org/residents/home-repair/adaptive-modifications-program/",
        },
        {
          id: 2,
          name: "Basic Systems Repair Program", //complete
          applicantInterested: false,
          phoneNumber: "215-448-2160",
          description: "message.bsrpDescription",
          website:
            "https://phdcphila.org/residents/home-repair/basic-systems-repair-program/",
        },
        {
          id: 3,
          name: "Built to Last",
          applicantInterested: false,
          phoneNumber: "215-686-4483",
          description: "message.builtToLastDescription",
          website: "https://philaenergy.org/programs-initiatives/built-to-last/",
        },
        {
          id: 4,
          name: "Heater Hotline", //complete
          applicantInterested: false,
          phoneNumber: "215-568-7190",
          description: "message.heaterDescription",
          website: "https://www.ecasavesenergy.org/heater-hotline",
        },
        {
          id: 5, //complete
          name: "Housing Counseling",
          applicantInterested: false,
          phoneNumber: "215-686-9749",
          description: "message.housingCounselingDescription",
          website:
            "https://www.phila.gov/departments/division-of-housing-and-community-development/neighborhood-resources/housing-counseling/",
        },
        {
          id: 6,
          name: "Neighborhood Energy Centers",
          applicantInterested: false,
          description: "message.necDescription",
          website: "https://www.phila.gov/departments/division-of-housing-and-community-development/neighborhood-resources-archive/neighborhood-energy-centers-necs/",
        },
        {
          id: 7,
          name: "PEA-Solarize Philly Program",
          applicantInterested: false,
          phoneNumber: "215-686-4483",
          description: "message.solarPowerDescription",
          website: "https://solarizephilly.org/",
        },
        {
          id: 8,
          name: "Philly First Home", //complete
          applicantInterested: false,
          phoneNumber: "Note: This program is currently on hold.", //phoneNumber: "215-686-9749",
          description: "message.pfhDescription",
          website:
            "https://phdcphila.org/residents/homebuyers-and-renters/philly-first-home/",
        },
        {
          id: 9,
          name: "Restore Repair Renew Loan Program", //complete
          applicantInterested: false,
          phoneNumber:
            "Call Clarifi (215-866-5200) to begin the intake process. ", //awilliams@philapcca.org, rrr@clarifi.org
          description: "message.tripleRDescription",
          website:
            "https://phdcphila.org/residents/home-repair/restore-repair-renew",
        },
        {
          id: 10,
          name: "Tangled Title", //complete
          applicantInterested: false,
          phoneNumber: "215-523-9564", //reverify phone
          description: "message.tangledTitleDescription", //old description: Grants to assist low-income residents gain clear legal title to their home.
          website: "https://www.phillyvip.org/tangled-title-fund/",
        },
        {
          id: 11,
          name: "Turn the Key", //complete
          applicantInterested: false,
          phoneNumber: "215-448-3000",
          description: "message.turnTheKeyDescription",
          website:
            "https://phdcphila.org/residents-and-landlords/homebuyers-and-renters/turn-the-key/",
        },
        {
          id: 12,
          name: "Utility Grant Program", //complete
          applicantInterested: false,
          phoneNumber: "215-972-5170",
          description: "message.utilityGrantDescription",
          website: "https://uesfacts.org/our-programs/utility-grant-program/",
        },
        {
          id: 13,
          name: "Weatherization Assistance Program",
          applicantInterested: false,
          phoneNumber: "1-866-466-3972",
          description: "message.wapDescription",
          website: "https://dced.pa.gov/programs/weatherization-assistance-program-wap/",
        },

      ],
    };
  },
  validations: {
    Name: { required },
    EmailAddress: { required },
    Address: {
      Street1: { required },
      City: { required },
      State: { required },
      Zip: { required },
    },
    Phone: { required },
    applicantInterestedList: { required }, //, minLength: 1
    TripleRSelection: {
      required: requiredIf(function () {
        const rrrProgram = this.programs.find(
          i => i.name === "Restore Repair Renew Loan Program",
        );
        return rrrProgram.applicantInterested; //Determines whether applicantInterested is true based on function()
      }),
    },
  },

  computed: {
    programSuggestions() {
      return this.programs.filter((program) =>
        this.checkEligibility(
          program.name,
          this.primaryResidencePath,
          this.childPath,
          this.adultsPath,
          this.incomePath,
          this.disabilityPath,
          this.homeownerPath,
          this.spropertyPath,
          this.deedPath,
          this.yearsPath,
          this.employeePath,
          this.roofLeakPath,
          this.electricProblemPath,
          this.plumbingProblemPath,
          this.structuralProblemPath,
          this.floorStairsPath,
          this.heaterOrHotWaterPath,
          this.helpInHomePath,
          this.tangleTitlePath,
          this.solarPowerPath,
          this.billProblemPath,
        ),
      );
    },
    //https://www.digitalocean.com/community/tutorials/4-uses-of-javascripts-arraymap-you-should-know
  },
  methods: { //https://www.phila.gov/departments/division-of-housing-and-community-development/about/income-guidelines/
    toggleReadRelease() {
      this.hasReadRelease = !this.hasReadRelease;
    },
    applicantInterestedEmailFormat() {
      return this.selectedPrograms
        .filter(program => program.applicantInterested)
        .map(program => ` <a href='${program.website}'>${program.name}</a>`);
    },
    calcAmp(c, a, i) {
      //calcAmp is also used for BSRP)
      if (parseInt(c) + parseInt(a) == 1 && i <= 44300) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 2 && i <= 50600) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 3 && i <= 56950) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 4 && i <= 63250) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 5 && i <= 68300) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 6 && i <= 73400) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 7 && i <= 78450) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 8 && i <= 83500) {
        return true;
      }
      if (
        parseInt(c) + parseInt(a) > 8 &&
        i <= 83500 + 5050 * (parseInt(c) + parseInt(a) - 8)
      ) {
        return true;
      }
      return false;
    },

    calcRenew(c, a, i) {
      //Used to calculate eligibility for restore, repair, renew
      if (parseInt(c) + parseInt(a) == 1 && i <= 88500) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 2 && i <= 101200) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 3 && i <= 113850) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 4 && i <= 126500) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 5 && i <= 136600) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 6 && i <= 146750) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 7 && i <= 156850) {
        return true;
      }
      if (parseInt(c) + parseInt(a) >= 8 && i <= 167000) {
        return true;
      }
      if (
        parseInt(c) + parseInt(a) > 8 &&
        i <= 167000 + 10100 * (parseInt(c) + parseInt(a) - 8)
      ) {
        return true;
      }
      return false;
    },

    calcAMI(c, a, i, multipler) {
      if (parseInt(c) + parseInt(a) == 1 && i <= 73800 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 2 && i <= 84350 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 3 && i <= 94900 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 4 && i <= 105400 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 5 && i <= 113850 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 6 && i <= 122300 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 7 && i <= 130700 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 8 && i <= 139500 * multipler) {
        return true;
      }
      if (
        parseInt(c) + parseInt(a) > 8 &&
        i <= (139500 * multipler) + (8450 * multipler) * (parseInt(c) + parseInt(a) - 8)
      ) {
        return true;
      }
      return false;
    },

    toggleConsentForm(event) {
      event.preventDefault(); // Prevent form submission
      var container = document.getElementById("consentFormContainer");
      var frame = document.getElementById("consentFormFrame");
      
      if (container.style.display === "none") {
        container.style.display = "block";
        frame.src = "https://dpd-discoverbenefits-documents.s3.amazonaws.com/Consent+release+form.pdf";
      } else {
        container.style.display = "none";
        frame.src = "";
      }
    },


    fedPovertyCalc(c, a, i, multipler) {
      //Used to calculate eligibility for programs that base income off of federal poverty guidelines, with a multiplier for income. https://aspe.hhs.gov/poverty-guidelines
      if (parseInt(c) + parseInt(a) == 1 && i <= 13590 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 2 && i <= 18310 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 3 && i <= 23030 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 4 && i <= 27750 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 5 && i <= 32470 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 6 && i <= 37190 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 7 && i <= 41910 * multipler) {
        return true;
      }
      if (parseInt(c) + parseInt(a) == 8 && i <= 46630 * multipler) {
        return true;
      }
      if (
        parseInt(c) + parseInt(a) > 8 &&
        i <= (46630 * multipler) + (4720 * multipler) * (parseInt(c) + parseInt(a) - 8)
      ) {
        return true;
      }
      return false;
    },

    checkEligibility(
      name,
      primaryResidencePath,
      c,
      a,
      i,
      disabilityPath,
      homeownerPath,
      spropertyPath,
      deedPath,
      yearsPath,
      employeePath,
      roofLeakPath,
      electricProblemPath,
      plumbingProblemPath,
      structuralProblemPath,
      floorStairsPath,
      heaterOrHotWaterPath,
      helpInHomePath,
      tangleTitlePath,
      solarPowerPath,
      billProblemPath,
    ) {
      // anything that is available in props or data is available from any other vue function via this.propertyname
      if (
        //used to display no qualifications if page is refreshed
        name == null &&
        primaryResidencePath == null &&
        c == null &&
        a == null &&
        i == null &&
        disabilityPath == null &&
        homeownerPath == null &&
        spropertyPath == null &&
        deedPath == null &&
        yearsPath == null &&
        employeePath == null &&
        roofLeakPath == null &&
        electricProblemPath == null &&
        plumbingProblemPath == null &&
        structuralProblemPath == null &&
        floorStairsPath == null &&
        heaterOrHotWaterPath == null &&
        helpInHomePath == null &&
        tangleTitlePath == null &&
        solarPowerPath == null &&
        billProblemPath == null
      ) {
        this.resultsValid = false;
        return false;
      }

      if (
        name == "Adaptive Modification Program") {
        if (
          primaryResidencePath == "Yes" &&
          disabilityPath == "Yes" &&
          spropertyPath == "No" &&
          this.calcAmp(c, a, i, 0.6) == true
        ) {
          if (
            homeownerPath == "Homeowner" &&
            deedPath == "Deed" &&
            (
              helpInHomePath != "No"
            )
          ) {
            this.resultsValid = true;
            return true;
          } else if (
            homeownerPath == "Renter" &&
            deedPath == "Lease" &&
            yearsPath >= "3" &&
            (
              helpInHomePath != "No"
            )
          ) {
            this.resultsValid = true;
            return true;
          }
          return false;

        }
        return false;

      }

      if (
        name == "Basic Systems Repair Program") {
        if (
          primaryResidencePath == "Yes" &&
          !this.resultsValid && // Check if "Built to Last" is not already displayed
          this.calcAmp(c, a, i, 0.6) == true &&
          spropertyPath == "No" &&
          deedPath == "Deed" &&
          homeownerPath == "Homeowner" &&
          heaterOrHotWaterPath == "No"
        ) {
          let tryCount = 0;
          const results = [roofLeakPath,
            plumbingProblemPath,
            structuralProblemPath,
            electricProblemPath,
            floorStairsPath];
          for (const result of results) {
            if (result !== "No") {
              tryCount++;
            }
          }
          if (tryCount >= 1 && tryCount <= 2) {
            return true;
          }
          this.resultsValid = true;
        }
        if (heaterOrHotWaterPath !== "No") {
          return false;
        }
        return false;
      }

      if (
        name == "Heater Hotline") {
        if (
          primaryResidencePath == "Yes" &&
          spropertyPath == "No" &&
          deedPath == "Deed" &&
          homeownerPath == "Homeowner" &&
          !this.resultsValid && // Check if "Built to Last" is not already displayed
          this.fedPovertyCalc(c, a, i, 1.5) == true
        ) {
          if (
            heaterOrHotWaterPath != "No"
          ) {
            this.resultsValid = true;
            return true;
          }
        } else if (
          heaterOrHotWaterPath == "No"
        ) {
          return false;
        }
        return false;
      }

      if (
        name == "Philly First Home") {
        if (
          this.calcAMI(c, a, i, 1) == true &&
          homeownerPath != "Homeowner" &&
          deedPath != "Deed" &&
          spropertyPath == "No"
        ) {
          this.resultsValid = true;
          return true;
        }
        return false;
      }

      if (name == "Housing Counseling") {
        if (
          primaryResidencePath == "Yes") {
          this.resultsValid = true;
          return true;
        }
        return false;
      }
      if (
        name == "Tangled Title") {
        if (
          primaryResidencePath == "Yes" &&
          //homeownerPath != "Renter" &&
          //this.fedPovertyCalc(c, a, i, 2) == true
          this.calcAMI(c, a, i, 0.8) == true &&//reverify multiplier
          deedPath == "Neither" &&
          tangleTitlePath === "Yes") {
          this.resultsValid = true;
          return true;
        }
        // ) {
        //   if (
        //     tangleTitlePath != "No") {
        //     return true;
        //   }
        // } else if (
        //   tangleTitlePath != "Yes") {
        //   return false;
        // }
        return false;
      }

      if (
        name == "Restore Repair Renew Loan Program") {
        if (
          primaryResidencePath == "Yes" &&
          this.calcRenew(c, a, i) == true &&
          i > 44000 &&
          homeownerPath == "Homeowner" &&
          deedPath == "Deed"
        ) {
          this.resultsValid = true;
          return true;
        }
        return false;
      }

      if (
        name == "Utility Grant Program") {
        if (
          primaryResidencePath == "Yes" &&
          this.fedPovertyCalc(c, a, i, 1.75) == true
        ) {
          this.resultsValid = true;
          return true;
        }
        return false;
      }

      if (
        name == "Turn the Key") {
        if (
          //removed primaryResidence question
          homeownerPath != "Homeowner" &&
          deedPath != "Deed" &&
          yearsPath >= "3" &&
          employeePath == "Yes" &&
          this.calcAMI(c, a, i, 1) == true
        ) {
          this.resultsValid = true;
          return true;
        }
        return false;
      }

      if (
        name === "PEA-Solarize Philly Program") {
        if (
          primaryResidencePath == "Yes" &&
          homeownerPath == "Homeowner" &&
          spropertyPath == "No"
        ) {
          if (
            solarPowerPath != "No") {
            this.resultsValid = true;
            return true;
          }
        } else if (
          solarPowerPath == "No"
        ) {
          return false;
        }
        return false;
      }

      if (
        name === "Built to Last") {
        if (
          primaryResidencePath == "Yes" &&
          homeownerPath == "Homeowner" &&
          spropertyPath == "No" &&
          this.calcAmp(c, a, i) == true
        ) {
          let selectedProgramsCount = 0;
          const btlPrograms = ["Basic Systems Repair Program", "Heater Hotline", "Weatherization Assistance Program"];
          for (const program of btlPrograms) {
            if (btlPrograms.includes(program)) {
              selectedProgramsCount++;
            }
          }
          if (selectedProgramsCount >= 2) {
            this.resultsValid = true;
            return true;
          }
        }
        return false;
      }

      if (
        name === "Neighborhood Energy Centers"
      ) {
        if (
          primaryResidencePath == "Yes" &&
          homeownerPath == "Homeowner" &&
          this.calcAmp(c, a, i) == true &&
          (
            billProblemPath !== "No"
          )
        ) {
          this.resultsValid = true;
          return true;
        } return false;
      }

      if (
        name === "Weatherization Assistance Program"
      ) {
        if (
          primaryResidencePath == "Yes" &&
          homeownerPath == "Homeowner" &&
          !this.resultsValid && // Check if "Built to Last" is not already displayed
          this.fedPovertyCalc(c, a, i, 2) &&
          (
            billProblemPath !== "No"
          )
        ) {
          this.resultsValid = true;
          return true;
        } return false;
      }
    },

    handleSubmit() {
      var vData = this;
      //this.$v.$touch();
      if (!vData.Name || !vData.EmailAddress || !vData.incomePath || !vData.Address || !vData.Phone || !vData.Street1 || !vData.City || !vData.State || !vData.Zip) {
        this.submitStatus = "ERROR";
        // return this.$alert(
        //   "Please enter all required information and try again.",
        // );
      }
      // Check if the user has affirmed the release
      if (!vData.hasReadRelease) {
        this.submitStatus = "ERROR";
        return this.$alert("Please affirm that you have read the release.");
      }
      // const selectedPrograms = this.programs.filter(program => program.applicantInterested);
      // if (selectedPrograms.length === 0) {
      //   this.submitStatus = "ERROR";
      //    return this.$alert("Please select at least one program.");
      // }
      
      this.applicantInterestedList = this.selectedPrograms.map(function(program){
        return program.name;
      });
      var list = [];
      for(var i = 0; i < this.applicantInterestedList.length; i++){
        list.push(this.applicantInterestedList[i]);
      }
      this.applicantInterestedList = list.join("; ");


      this.$v.$touch();
      this.submitStatus = "PENDING";

      const dataObject = buildApplicationDataObject(vData);
      const xmlDataObject = dataObjectToXml(dataObject);
      const requestObj = {
        table: "bqj7i59gi",
        action: "API_AddRecord",
        data: xmlDataObject,
        fieldToEmail: {
          //test email via Lambda
          lambdaEmail: vData.EmailAddress,
          lambdaIncome: vData.incomePath,
          lambdaFirstName: vData.Name,
          lambdaPrograms: vData.applicantInterestedEmailFormat(),
        },
      };
      //debugger;  //disabled debugger for alerts
      axios
        .post(
          "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/V1/Discover-Benefits-QuickBase-Request-Proxy",
          requestObj,
        )
        .then(() => {
          this.submitStatus = "OK";
          this.$alert(
            "Your request has been submitted. You will receive a confirmation email shortly.",
          );
        })
        .catch(() => {
          vData.submitStatus = "POST_ERROR";
        });
    },
  },
};
</script>

<style scoped>
.dropdown {
  display: inline-block;
  margin-left: 10px;
}

.dropbtn {
  background-color: #1d7cf0;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

#consentFormContainer {
  margin-top: 10px;
}
</style>