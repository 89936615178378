<template>
  <header class="header">
    <div class="header-container">
      <!-- Left side - always visible -->
      <div class="header-left">
        <div class="city-logo">
          <a 
            href="https://www.phila.gov" 
            target="_blank"
          >
            <img 
              src="https://www.phila.gov/wp-content/themes/phila.gov-theme/img/city-of-philadelphia-logo.svg" 
              alt="City of Philadelphia"
            >
          </a>
        </div>
      </div>

      <!-- Hamburger menu button -->
      <button 
        class="hamburger-button" 
        @click="toggleMenu"
      >
        <i class="fas fa-bars" />
      </button>

      <!-- Menu content -->
      <div 
        class="menu-overlay" 
        :class="{ 'active': isMenuOpen }" 
        @click="closeMenu"
      />
      <div 
        class="menu-content" 
        :class="{ 'active': isMenuOpen }"
      >
        <div class="menu-header">
          <button 
            class="close-button" 
            @click="closeMenu"
          >
            <i class="fas fa-times" />
          </button>
        </div>
        <nav class="menu-nav">
          <a 
            href="https://www.phila.gov/departments/department-of-planning-and-development/" 
            target="_blank"
          >Department of Planning and Development</a>
          <a 
            href="https://phdcphila.org/" 
            target="_blank"
          >Philadelphia Housing Development Corporation</a>
          <div class="lang-selector">
            <i 
              class="fas fa-earth-americas" 
              aria-hidden="true"
            />
            <select 
              v-model="$i18n.locale" 
              aria-label="select language"
            >
              <head>
                <link 
                  rel="stylesheet" 
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
                >
              </head>
              <option 
                v-for="(lang, i) in langs" 
                :key="`Lang${i}`" 
                :value="lang"
              >
                {{ lang }}
              </option>
            </select>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      langs: ['English', 'Spanish'],
      isMenuOpen: false,
    };
  },
  mounted() {
    // Add event listener to handle navigation
    const homeLink = document.querySelector('.home-icon-link');
    homeLink.addEventListener('click', (e) => {
      e.preventDefault();
      window.location.href = '/';
    });
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped>
@media (min-width: 1025px) {
 .menu-header {
   display: none;
 }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  height: 60px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.city-logo img {
  height: 30px;
}

.home-link i {
  font-size: 24px;
  color: #0f4d90;
}

.hamburger-button {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: #0f4d90;
  cursor: pointer;
  padding: 5px;
}
.menu-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .menu-nav {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .menu-nav a {
    color: #0f4d90;
    text-decoration: none;
    font-size: 1.1em;
  }

  .lang-selector {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 20px;
    width: auto;
  }

  .lang-selector select {
    width: 120px;
    margin-left: 8px
  }

  .fa-earth-americas {
    font-size: 20px;
    color: rgb(109, 109, 202);
    display: inline-block;
    margin-bottom: 16px;
  }

@media (max-width: 1024px) {
  .hamburger-button {
    display: block;
  }

  .menu-content {
    position: fixed;
    top: 60px;
    right: -300px;
    width: 300px;
    height: calc(100vh - 60px);
    background: white;
    padding: 20px;
    box-shadow: -2px 0 4px rgba(0,0,0,0.1);
    transition: right 0.3s ease;
  }

  .menu-content.active {
    right: 0;
  }

  .menu-overlay {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: rgba(0,0,0,0.5);
  }

  .menu-overlay.active {
    display: block;
  }

  .menu-nav {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .menu-nav a {
    color: #0f4d90;
    text-decoration: none;
    font-size: 1.1em;
  }

  .lang-selector {
    margin-top: 20px;
  }
}

/* Add margin to landing page container */
:global(.landing-container) {
  margin-top: 80px !important;
}
</style>
